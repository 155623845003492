import { Pipe, PipeTransform } from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

@Pipe({
  name: 'getFormArrayAtIndex',
})
export class GetFormArrayAtIndexPipe implements PipeTransform {

  transform(value: FormGroup, formArrayName: string, index: number): FormGroup {
    if (!value || !formArrayName || index < 0) {
      return new FormGroup({});
    }

    return (value.get(formArrayName) as FormArray).at(index) as FormGroup;
  }

}
