import {Component} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'ngx-modale-confirmation',
  templateUrl: './modale-confirmation.component.html',
  styleUrls: ['./modale.scss'],
})
export class ModaleConfirmationComponent {

  title: string = ''
  message: string = ''

  constructor(
    public readonly activeModal: NgbActiveModal,
  ) {}

  public ok(): void {
    this.activeModal.close(true);
  }

  public dismiss(): void {
    this.activeModal.close(false);
  }
}
