<div class="modal-header">
  <h5 class="modal-title" id="modaleDelLabel">Importer des moyens</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
</div>
<div class="modal-body">
  <label>Site </label>
  <select class="form-select" [(ngModel)]="selected_usine">
    <option *ngFor="let usine of usines" [ngValue]="usine.value">{{usine.name}}</option>
  </select>
  <div class="inline">
    <div class="form-group mt-2">
      <label for="import_moyen">Fichier Excel à importer</label>
      <input class="form-control importfile" accept=".xlsx"
             name="import_moyen" type="file"
             id="import_moyen" (change)="onFileChange($event.target.files[0])"/>
    </div>
  </div>
</div>
<div *ngIf="showError" class="alert alert-danger" role="alert">{{ errorTxt }}</div>
<div class="modal-footer">
  <button [disabled]="!importMoyen" type="button" class="btn btn-primary" (click)="validate()">Importer</button>
  <button class="btn btn-warning" type="button" data-dismiss="modal" aria-label="Close" (click)="dismiss()">Annuler</button>
</div>
