<div class="header-container"
     [class.left]="position === 'normal'"
     [class.right]="position === 'inverse'">
  <div class="logo-containter">
    <a (click)="toggleSidebar()" href="#" class="navigation"><nb-icon icon="menu-2-outline"></nb-icon></a>
    <div class="logo-wrapper" (click)="goToHome()"><img src="/assets/images/logo.png" class="logo"/></div>
  </div>
</div>

<div class="header-container">
  <nb-actions
    size="medium"
    [class.right]="position === 'normal'"
    [class.left]="position === 'inverse'">
    <nb-action disabled></nb-action>
    <nb-action *nbIsGranted="['view', 'user']">
      <nb-user class="click" [nbContextMenu]="userMenu" [name]="getNomComplet()"></nb-user>
    </nb-action>
    <nb-action *ngIf="boutonFiltreService.isVisible" class="control-item click" icon="search-outline" (click)="clickOnFilterButton()"></nb-action>
  </nb-actions>
</div>
