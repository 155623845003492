import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from "rxjs";
import { AUTH_TOKEN_V2_STORAGE_KEY } from '../auth.guard';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem(AUTH_TOKEN_V2_STORAGE_KEY);
    if (token && request.url.startsWith(environment.apiPathV2) && !request.url.endsWith('/login')) {
      request = request.clone({
        setHeaders: {
          Authorization: token
        }
      });
    }
    return next.handle(request);
  }
}
