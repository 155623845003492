import {Component, OnInit, ViewChild} from '@angular/core';
import {MoyenService} from '../../../../@core/services/moyen.service';
import {MoyenModel} from '../../../../@core/models/moyens/moyen.model';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {GridOptions} from 'ag-grid-community';
import {AgGridAngular} from 'ag-grid-angular';

export interface ConfModel {
  idUsine: number,
  listeMoyens: Array<MoyenModel>,
  showPrix?: boolean,
}

@Component({
  selector: 'ngx-modale-moyen-ag-grid',
  templateUrl: './modale-moyen-ag-grid.component.html',
  styleUrls: ['./modale-moyen-ag-grid.component.scss'],
})

export class ModaleMoyenAgGridComponent implements ConfModel, OnInit {

  @ViewChild('agMoyen') agMoyen: AgGridAngular;

  categories: any = [];
  agGridOptions: GridOptions = {
    defaultColDef: {
      filter: true,
      sortable: true,
      resizable: true,
    },
  };
  agGridData: any = [];

  filtre_mot_cle: string;

  idUsine: number;
  listeMoyens: Array<MoyenModel>;
  ColumnDef: any;
  showPrix: boolean = false;

  constructor(private readonly moyenService: MoyenService,
              private readonly activeModal: NgbActiveModal) {}

  public ngOnInit(): void {
    this.moyenService.getCategories(this.idUsine).subscribe(
      data => {
      for (let i = 0; i < data.length; i++) {
        this.categories.push({'name': data[i].categorie,
          nombre: data[i].nombre,
          actif: false,
          type : []});
      }
    });

    this.agGridOptions = {
      getRowId: function (item) {
        return item.data.id;
      },
      onRowClicked: function (item) {
        if (item.node.isSelected()) {
          item.node.setSelected(false, false);
        } else {
          item.node.setSelected(true);
        }
      },
    };

    this.ColumnDef = [
      {
        headerName: '', checkboxSelection: true, width: 35, pinned: 'left', lockPinned: true,
        lockPosition: true, cellClass: 'fixed-size-header', headerClass: 'fixed-size-header', menuTabs: [],
      },
      {headerName: 'id', field: 'id', hide: true},
      {headerName: 'Catégorie', field: 'categorie', menuTabs: ['filterMenuTab']},
      {headerName: 'Type', field: 'type', menuTabs: ['filterMenuTab']},
      {headerName: 'Matière', field: 'matiere', menuTabs: ['filterMenuTab']},
      {headerName: 'DN', field: 'dn', menuTabs: ['filterMenuTab']},
      {headerName: 'Dimension', field: 'dimension', menuTabs: ['filterMenuTab']},
    ];

    if(this.showPrix){
      this.ColumnDef.push({headerName: 'Prix d\'Achat', field : 'prix_achat', valueFormatter: this.formmatterPrix, menuTabs: ['filterMenuTab']},
        {headerName: 'Prix de Vente', field : 'prix_vente', valueFormatter: this.formmatterPrix, menuTabs: ['filterMenuTab']});
    }

    this.ColumnDef.push({headerName: 'Désignation', field: 'designation', menuTabs: ['filterMenuTab']},
    {headerName: 'Magasin', field: 'magasin', menuTabs: ['filterMenuTab']},
    {headerName: 'Code REF', field: 'code_ref', menuTabs: ['filterMenuTab']},
    {headerName: 'Libellé', field: 'libelle', menuTabs: ['filterMenuTab']},
    {headerName: 'Nombre', field: 'nombre', hide: true},);
  }

  getMatiere(categorie, type) {
    type.matiere = [];
    const temp = {'site': this.idUsine, 'categorie': categorie, 'type': type.name};
    this.moyenService.getMatiere(temp).subscribe(
    data => {
        for (let i = 0; i < data.length; i++) {
          type.matiere.push({'name': data[i].matiere,
            nombre: data[i].nombre});
        }
    });
    type.actif = !type.actif;
  }

  filtreMoyenMotCle() {
    let filtre = new MoyenModel();
    filtre.site = this.idUsine;
    filtre.text = this.filtre_mot_cle;
    this.moyenService.getMoyenByKeyWord(filtre).subscribe(
      data => this.agGridData = data
    )
  }

  getMoyens(categorie, type, matiere) {
    const temp = {'site': this.idUsine, 'categorie': categorie, 'type': type, 'matiere': matiere.name};
    this.moyenService.getListMoyens(temp).subscribe(
      data => this.agGridData = data
    )
  }

  getType(categorie) {
    categorie.type = [];
    let temp = {'site': this.idUsine, 'categorie': categorie.name};
    this.moyenService.getType(temp).subscribe(
      data => {
        for (let i  = 0; i < data.length; i++) {
          categorie.type.push({'name': data[i].type,
          nombre: data[i].nombre,
          actif: false,
          matiere : []});
      }
    });
    categorie.actif = !categorie.actif;
  }

  validate() {
    this.setMoyenClass(this.agMoyen.api.getSelectedRows());
    this.dismiss();
  }

  dismiss(){
    this.activeModal.close(null);
  }

  setMoyenClass(recurrences) {
    let bool = false;
    this.listeMoyens.forEach(function(row) {
      row['bool'] = false;
    })
    for (let i = 0; i < recurrences.length; i++) {
      this.listeMoyens.forEach(function(row) {
        if (row.id == recurrences[i].id) {
          row.nombre += recurrences[i].nombre;
          bool = true;
          row['bool'] = true;
        }
      });
      if (bool == false) {
        this.listeMoyens.unshift(recurrences[i]);
        this.listeMoyens[0]['bool'] = true;
        this.listeMoyens[0].nombre = 0
      }
      bool = false;
    }
    this.listeMoyens.forEach((value, index) => {
      value.order = index + 1;
    });
  }

  formmatterPrix(params){
    if(params.value){
      return params.value + ' €';
    }
    else {
      return '';
    }
  }

}
