import { DestroyRef, Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NewmAlert, NewmError, NewmWarning } from '../models/error/newm-alert.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from '../../../environments/environment';
import { ErrorService } from '../services/error/error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private readonly errorService: ErrorService,
        private readonly destroyRef: DestroyRef
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(({ error, status, name, message }: HttpErrorResponse) => {
              if (!request.url.startsWith(environment.apiPathV2)) {
                return throwError(() => error)
              }

              let alert: NewmAlert;
              if (typeof error === 'string') {
                const errorJson = JSON.parse(error);
                alert = error ?
                  new NewmError(name, errorJson.message, errorJson.code, errorJson.details)
                  : new NewmError(name, message);
              } else if (error instanceof Blob) {
                // https://github.com/angular/angular/issues/19888
                // When request of type Blob, the error is also in Blob instead of object of the json data
                const reader = new FileReader();
                reader.addEventListener('loadend', (e) => {
                  const clonedError = JSON.parse(e.srcElement['result']);
                  alert = new NewmWarning(clonedError.name, clonedError.message, clonedError.code, clonedError.details);
                });
                reader.readAsText(error);
              } else if (error instanceof ArrayBuffer) {
                const clonedError = JSON.parse(new TextDecoder().decode(error));
                alert = new NewmWarning(clonedError.name, clonedError.message, clonedError.code, clonedError.details);
              } else {
                alert = new NewmError(name, error.message ?? message, error.code, error.details);
              }

              this.errorService.handle(alert);
              return throwError(() => alert);
            }),
            takeUntilDestroyed(this.destroyRef)
        );
    }

}
