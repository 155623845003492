import {UserDroitModel} from './userDroit.model';

export class UserProfileModel {

  id: number;
  code: string;
  libelle: string;
  description: string;
  actif: boolean;
  dateCreation: Date;
  dateMaj: Date;
  isDelete: boolean;
  admin: boolean;

  listDroit: Array<UserDroitModel>;

  constructor() {
    this.actif = true;
  }
}
