import { NgbTimeStruct, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";

export interface NgbDateTimeStruct extends NgbDateStruct, NgbTimeStruct {}

export class DateTimeModel implements NgbDateTimeStruct {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;

  timeZoneOffset: number;

  public constructor(init?: Partial<DateTimeModel>) {
    Object.assign(this, init);
  }

  public static fromLocalString(dateString: string): DateTimeModel {

    const date = new Date(dateString);

    const isValidDate = !isNaN(date.valueOf());

    if (!dateString || !isValidDate) {
      return null;
    }

    return new DateTimeModel({
      year: date.getUTCFullYear(),
      month: date.getUTCMonth() + 1,
      day: date.getUTCDate(),
      hour: date.getUTCHours(),
      minute: date.getUTCMinutes(),
      second: date.getUTCSeconds(),
    });
  }

  private isInteger(value: any): value is number {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  }

  public toString(): string {
    this.year = this.year ? this.year : 1970;
    this.month = this.month ? this.month : 1;
    this.day = this.day ? this.day : 1;
    const monthFormated = this.month < 10 ? `0${this.month}` : this.month.toString();
    const dayFormated = this.day < 10 ? `0${this.day}` : this.day.toString();

    const dateFormated = `${this.year}-${monthFormated}-${dayFormated}`;

    this.hour = this.hour ? this.hour : 0;
    this.minute = this.minute ? this.minute : 0;
    this.second = this.second ? this.second : 0;

    const hourFormated = this.hour < 10 ? `0${this.hour}` : this.hour.toString();
    const minuteFormated = this.minute < 10 ? `0${this.minute}` : this.minute.toString();
    const secondFormated = this.second < 10 ? `0${this.second}` : this.second.toString();


    const isoString = `${dateFormated}T${hourFormated}:${minuteFormated}:${secondFormated}`;

    return isoString;
    }

}
