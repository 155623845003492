import {UserDroitModel} from './userDroit.model';
import {UserProfileModel} from './userProfile.model';
import {BusinessUnitModel} from '../businessUnit';
import {UserDroitEnum} from '../enum/userDroit.enum';

export class UserModel {

  id: number;
  email: string;
  lastName: string;
  firstName: string;
  password: string;
  active: boolean;
  ts_creation: Date;
  ts_update: Date;
  idBusinessUnit: number;
  role: string;
  listDroit: Array<UserDroitModel>;
  listUsines: Array<BusinessUnitModel>;
  profile: UserProfileModel;
  delete: boolean;
  listAffectation: any;



  reset() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.password = '';
    this.active = false;
    this.idBusinessUnit = null;
    this.role = '';
    this.listDroit = null;
  }

  fromUser(pUser: UserModel): UserModel {
    const lUser = new UserModel();
    lUser.id = pUser.id;
    lUser.email = pUser.email;
    lUser.lastName = pUser.lastName;
    lUser.firstName = pUser.firstName;
    lUser.password = pUser.password;
    lUser.active = pUser.active;
    lUser.ts_creation = pUser.ts_creation;
    lUser.ts_update = pUser.ts_update;
    lUser.idBusinessUnit = pUser.idBusinessUnit;
    lUser.role = pUser.role;
    lUser.listDroit = pUser.listDroit;
    lUser.listUsines = pUser.listUsines;
    lUser.profile = pUser.profile;
    lUser.listAffectation = pUser.listAffectation;
    return lUser;
  }

  hasDroit(pKey: string): boolean {
    if (this.profile && this.profile.admin === true ) {
      return true;
    }
    if (this.listDroit != null) {
      for (const element of this.listDroit) {
        if (element.code === pKey) {
          return true;
        }
      }
      if (pKey.startsWith(UserDroitEnum.FICHE_PREPARATION_CONSULTATION)) {
        for (const element of this.listDroit) {
          if (element.code === pKey.replace(UserDroitEnum.FICHE_PREPARATION_CONSULTATION, UserDroitEnum.FICHE_PREPARATION_EDITION)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  startWith(pKey: string): boolean {
    if (this.profile && this.profile.admin === true ) {
      return true;
    }
    if (this.listDroit != null) {
      for (const element of this.listDroit) {
        if (element.code.startsWith(pKey)) {
          return true;
        }
      }
      if (pKey === UserDroitEnum.FICHE_PREPARATION_CONSULTATION) {
        for (const element of this.listDroit) {
          if (element.code.startsWith(UserDroitEnum.FICHE_PREPARATION_EDITION)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  hasUsine(idUsine: number): boolean {
    if (this.listUsines != null) {
      for (const element of this.listUsines) {
        if (element.id === idUsine) {
          return true;
        }
      }
    }
    return false;
  }

  hasAtelier(idAtelier: number): boolean {
    if (this.listAffectation != null) {
      for (const element of this.listAffectation) {
        if (element.id_atelier === idAtelier && element.checked === true) {
          return true;
        }
      }
    }
    return false;
  }
}
