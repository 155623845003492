import {UserAffectationModel} from "./user/userAffectation.model";
import {UserDroitModel} from "./user/userDroit.model";

export class TreeNode {
  name?: string;
  checked: boolean;
  id: number;
  children?: TreeNode[];
  idParent?: number;
  id_atelier?: number;
  code?: string;
  libelleLong?: string;
  description?: string;
  dateCreation?: Date;
  dateMaj?: Date;
  isDelete?: boolean;
  expanded?: boolean;

  public mapGenericToNodes(data: any) {
    if (data instanceof UserAffectationModel) {
      return this.mapUsinesToNodes(data);
    } else if (data instanceof UserDroitModel) {
      return this.mapDroitsToNodes(data);
    } else {
      return this;
    }
  }

  public mapUsinesToNodes(data: UserAffectationModel) {
      this.name = data.libelleCourt;
      this.checked = data.checked;
      this.id= data.id;
      this.idParent= data.idParent;
      this.id_atelier= data.id_atelier;
      this.expanded = false;
      return this;
  }

  public mapDroitsToNodes(data: UserDroitModel){
      this.name= data.libelleCourt;
      this.checked= data.checked;
      this.id= data.id;
      this.idParent= data.idParent;
      this.code= data.code;
      this.libelleLong= data.libelleLong;
      this.description= data.description;
      this.dateCreation= data.dateCreation;
      this.dateMaj= data.dateMaj;
      this.isDelete= data.isDelete;
      this.expanded = false;
      return this;
  }
}

