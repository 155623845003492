import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { NewmAlert, NewmError } from '../../models/error/newm-alert.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private readonly errorBehavior: Subject<NewmAlert> = new Subject<NewmError>();
  public readonly error = this.errorBehavior.asObservable();

  constructor() {}

  public handle(e: NewmAlert): void {
    if (!e) {
      e = new NewmError('Erreur sans nom', undefined);
    }

    if (e.message === undefined || e.message === null) {
      e.message = 'Une erreur est survenue lors d\'un appel au serveur';
    }

    this.errorBehavior.next(e);
  }
}
