<button id="btn" class="label-file btn btn-default" (click)="openColumnManager()">
  <span class="fa fa-gear"></span>
</button>

<ng-template #columnManager let-offcanvas>
  <div class="offcanvas-header">
    <h4 class="offcanvas-title">Gestion des colonnes</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body">
    <ag-grid-angular
      [ngClass]="{'hide-grid': this.loading}"
      class="ag-theme-balham h-100"

      [gridOptions]="gridOptions"
      [rowData]="columnDefs"

      (gridReady)="onGridReady($event)"
      (cellClicked)="onRowClicked($event)"
      (rowDragEnd)="refreshOrders($event)"
      (rowDragLeave)="refreshOrders($event)"
      (rowSelected)="refreshVisibility($event)"
      (rowDataUpdated)="onRowDataChanged($event)"
    />
  </div>
  <button type="button" class="btn btn-primary" (click)="offcanvas.dismiss('closed')">Fermer</button>
</ng-template>
