export class MoyenModel {

  public id: number;
  public site: number;
  public magasin: string;
  public categorie: string;
  public type: string;
  public matiere: string;
  public dn: string;
  public dimension: string;
  public nombre: number;
  public commentaire: string;
  public code_ref: string;
  public designation: string;
  public libelle: string;
  public pageSize: number;
  public page: number;
  public id_fiche_preparation: number;
  public id_fiche_moyen: number;
  public dateMaj: Date;
  public order: number;
  public bool: boolean;
  public prix_achat: number;
  public prix_vente: number;
  idSite: number;
  text: string;


}
