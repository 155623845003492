<ul class="list-group">
  <ng-container *ngFor="let node of nodes">
    <li class="list-group-item" [className]="!hasChildren(node) ? 'expanded' : 'collapsed'">
      <div>
        <nb-icon *ngIf="node.children  && !node.expanded" class="control-item click" icon="arrow-ios-forward-outline" (click)="toggleNode(node)" pack="eva" ></nb-icon>
        <nb-icon *ngIf="node.children  && node.expanded" class="control-item click" icon="arrow-ios-downward-outline" (click)="toggleNode(node)" pack="eva"></nb-icon>
        <input type="checkbox" class="form-check-input" [checked]="node.checked" (change)="onCheckboxChange(node)" [indeterminate]="isIndeterminate(node)" />
        {{ node.name }}
      </div>
      <div class="child-treeview">
        <app-treeview *ngIf="node.expanded" [nodes]="node.children"></app-treeview>
      </div>
    </li>
  </ng-container>
</ul>

