// FOR SYNCFUSION GANTT
// Angular
import '@angular/platform-browser';
import '@angular/platform-browser-dynamic';
import '@angular/core';
import '@angular/common';
import '@angular/common/http';
import '@angular/router';
// RxJS
import 'rxjs';
import * as $ from 'jquery';
import 'jsrender';

window['jQuery'] = $;
window['$'] = $;

