import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class MenuService {

  public isOpen: boolean;
  public observableToggle: Observable<boolean>;
  private observableToggleSubject: Subject<boolean>;

  constructor() {
    this.isOpen = true;
    this.observableToggleSubject = new Subject();
    this.observableToggle = this.observableToggleSubject.asObservable();
  }

  public toggleOpen(): void {
    this.isOpen = !this.isOpen;
    this.observableToggleSubject.next(this.isOpen);
  }
}
