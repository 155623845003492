import { NewmAlertEnum } from '../enum/newm-alert.enum';

export abstract class NewmAlert {
    name?: string;
    message?: string;
    type: NewmAlertEnum;

    protected constructor(type: NewmAlertEnum, name?: string, message?: string) {
        this.type = type;
        this.name = name;
        this.message = message;
    }
}

export class NewmError extends NewmAlert {
    code?: string;
    details?: string;

    constructor(name?: string, message?: string, code?: string, details?: string) {
        super(NewmAlertEnum.ERROR, name, message);
        this.code = code;
        this.details = details;
    }
}

export class NewmWarning extends NewmAlert {
    code?: string;
    details?: string;

    constructor(name?: string, message?: string, code?: string, details?: string) {
        super(NewmAlertEnum.WARNING, name, message);
        this.code = code;
        this.details = details;
    }
}

export class NewmSuccess extends NewmAlert {
    code?: string;
    details?: string;

    constructor(name?: string, message?: string, code?: string, details?: string) {
        super(NewmAlertEnum.SUCCESS, name, message);
        this.code = code;
        this.details = details;
    }
}
