import {Component, OnInit, ViewChild} from '@angular/core';
import {UserProfileModel} from '../../../../../@core/models/user/userProfile.model';
import {UserDroitFilterModel} from '../../../../../@core/models/user/userDroitFilter.model';
import {UserDroitModel} from '../../../../../@core/models/user/userDroit.model';
import {DroitService} from '../../../../../@core/services/users/droit.service';
import {UserModel} from '../../../../../@core/models/user/user.model';
import {UserDroitEnum} from '../../../../../@core/models/enum/userDroit.enum';
import {UsersService} from '../../../../../@core/services/users.service';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TreeNode} from "../../../../../@core/models/treenode.model";
import {CreateTreeNode} from "../../../../../@core/models/createTreeNode.model";

export interface ConfModel {
  selectedProfile: UserProfileModel,
}

@Component({
  selector: 'ngx-gestion-moyens-modale-edition-profile',
  templateUrl: './modale-edition-profil.component.html',
  styleUrls: ['../modale.scss'],
  providers: [CreateTreeNode,]
})

export class ModaleEditionProfilComponent implements ConfModel, OnInit {
  public usines: any;
  public selectedProfile: UserProfileModel;
  public tabFiltre: any;
  public showError: boolean;
  public errorTxt: string;
  public currentUser: UserModel;
  public droitGestionProfilModifEtat: boolean;
  public droitGestionProfilSupprimer: boolean;
  public nodesDroits: TreeNode[];

  public isAdmin = false;

  constructor(private readonly droitService: DroitService,
              private readonly userService: UsersService,
              private readonly activeModal: NgbActiveModal,
              private readonly createTreeNode: CreateTreeNode) {}

  public ngOnInit(): void {
    this.userService.getMe().subscribe(
      (user) => {
        if (user.listDroit.length === 0) {
          this.userService.setErrorAuth(true);
          localStorage.clear();
          window.location.reload();
        } else {
          this.currentUser = user;
          this.isAdmin = (this.currentUser && this.currentUser.profile) ? this.currentUser.profile.admin : false;
        }
      },
      (err) => {
        localStorage.clear();
        this.userService.setErrorAuth(true);
        window.location.reload();
      },
      () => {
        this.droitGestionProfilModifEtat = this.currentUser.hasDroit(UserDroitEnum.GESTION_PROFILS_MODIFICATION_RAPIDE_ETAT);
        this.droitGestionProfilSupprimer = this.currentUser.hasDroit(UserDroitEnum.GESTION_PROFILS_SUPPRESSION);
      });
    this.tabFiltre = [];
    this.showError = false;
    this.errorTxt = '';
    this.loadDroits();
  }

  public validate(): void {
    this.showError = false;
    if (!this.selectedProfile.libelle || this.selectedProfile.libelle === '') {
      this.errorTxt = 'Veuillez renseigner un libellé';
      this.showError = true;
    } else {
      if (this.selectedProfile['dateString']) {
        delete this.selectedProfile['dateString'];
      }
      let filterDroit;
      if ((this.treeView.getAllNodesFromTree() && this.treeView.getAllNodesFromTree().length > 0) || this.selectedProfile.id == null) {
        filterDroit = this.treeView.getAllNodesCheckedFromTree();
      } else {
        filterDroit = this.selectedProfile.listDroit.filter(droit => droit.checked);
      }
      this.selectedProfile.listDroit = [];
      filterDroit.forEach(droitFilter => {
        this.selectedProfile.listDroit.push(new UserDroitModel().fromNodes(droitFilter))
      });
      this.showError = false;
      this.activeModal.close(this.selectedProfile);
    }
  }

  public dismiss(): void {
    this.activeModal.close(null);
  }

  public select(champ: string, text: string): void {
    this.selectedProfile[champ] = text;
    this.tabFiltre[champ] = [];
  }

  changeText(textChange: string) {
    this.selectedProfile.code = ModaleEditionProfilComponent.getCodeByLibelle(textChange);
  }

  private static getCodeByLibelle(textChange: string) {
    return textChange.replace(' ', '')
      .replace(/[éÉèÈêëÊË]/g, 'e')
      .replace(/[àÀâÂäÄ]/g, 'a')
      .replace(/[ùÙûÛüÜ]/g, 'u')
      .replace(/[ôöÖÔòÒ]/g, 'o')
      .toUpperCase();
  }


  private loadDroits() {
    const lUserdroitFilter = new UserDroitFilterModel();
    lUserdroitFilter.idProfile = this.selectedProfile.id;
    lUserdroitFilter.all = true;
    this.droitService.getListDroit(lUserdroitFilter).subscribe(
      (data: Array<UserDroitModel>) => {
        this.nodesDroits = this.createTreeNode.createTree(data, 'droit');
      });
  }

  public setActif(actif: boolean) {
    this.selectedProfile.actif = actif;
  }

  @ViewChild('treeView', {static: true})
  public treeView;
  public droitField;
  public allowEditing: boolean = false;
  public autoCheckParentNode: boolean = false;
  public autoCheck: boolean = true;

  setAdmin(pIsAdmin: boolean) {
    this.selectedProfile.admin = pIsAdmin;
  }

  deleteProfile(pId) {
    // this.result = this.selectedProfile;
    // this.result.delete = true;
    // this.close();
  }
}
