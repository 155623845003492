<div class="modal-header">
  <h5 class="modal-title" id="modaleDelLabel">{{title}}</h5>
  <button type="button" class="btn btn-light" style="margin-left: auto;" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true"><nb-icon icon="close-outline"></nb-icon></span>
  </button>
</div>
<div class="modal-body">
  {{message}}
</div>
<div class="modal-footer">
  <button class="btn btn-primary" type="button" (click)="ok()" ngbAutofocus>OK</button>
  <button class="btn btn-warning" type="button" (click)="dismiss()">Annuler</button>
</div>
