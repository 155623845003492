import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BoutonFiltreService {

  public openFilter: boolean;
  public isVisible: boolean;
  public observableFiltre: Observable<boolean>;
  private observableFiltreSubject: Subject<boolean>;

  constructor() {
    this.openFilter = false;
    this.isVisible = false;
    this.observableFiltreSubject = new Subject();
    this.observableFiltre = this.observableFiltreSubject.asObservable();
  }

  public setOpenFilter(open: boolean): void {
    this.openFilter = open;
  }

  public toogleFilter(): void {
    this.openFilter = !this.openFilter;
    this.observableFiltreSubject.next(this.openFilter);
  }

  public setButtonVisibility(visible: boolean): void {
    this.isVisible = visible;
  }
}
