<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5
        class="text-center">{{singleSelectedMoyen.id > 0 ? "Éditer un moyen" : "Ajouter un moyen"}}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
    </div>
    <div class="modal-body">
      <div class="container">
        <div style="flex-wrap: wrap;" class="panel panel-body panel-default d-flex">
          <div class="form-group col-lg-6 div-modal">
            <label>Site </label>
            <select class="form-select" [(ngModel)]="singleSelectedMoyen.idSite">
              <option *ngFor="let usine of usines" [ngValue]="toNumber(usine.value)">{{usine.name}}</option>
            </select>
          </div>
          <div class="col-lg-6 div-modal">
            <label>Magasin</label>
            <div class="form-group position-relative">
              <input [disabled]="!singleSelectedMoyen.idSite" type="text" class="form-control"
                     [(ngModel)]="singleSelectedMoyen.magasin"
                     (ngModelChange)="filtre('magasin', singleSelectedMoyen.magasin)"
                     (click)="filtre('magasin',singleSelectedMoyen.magasin)"
                     (blur)="blur('magasin');"/>
              <div
                *ngIf="(tabFiltre.magasin != null && tabFiltre.magasin.length > 0) && bool_filtre.magasin"
                class="list-propositions dynamic-search-input">
                <button type="button" class="list-group-item item"
                        *ngFor="let magasin of tabFiltre.magasin"
                        (click)="select('magasin',magasin)">
                  {{ magasin }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 div-modal">
            <label>Catégorie</label>
            <div class="form-group position-relative">
              <input [disabled]="!singleSelectedMoyen.idSite" type="text" class="form-control"
                     [(ngModel)]="singleSelectedMoyen.categorie"
                     (ngModelChange)="filtre('categorie', singleSelectedMoyen.categorie)"
                     (click)="filtre('categorie',singleSelectedMoyen.categorie)"
                     (blur)="blur('categorie');"/>
              <div
                *ngIf="(tabFiltre.categorie != null && tabFiltre.categorie.length > 0) && bool_filtre.categorie"
                class="list-propositions dynamic-search-input">
                <button type="button" class="list-group-item item"
                        *ngFor="let categorie of tabFiltre.categorie"
                        (click)="select('categorie',categorie)">
                  {{ categorie }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 div-modal">
            <label>Type</label>
            <div class="form-group position-relative">
              <input [disabled]="!singleSelectedMoyen.idSite || singleSelectedMoyen.categorie == null"
                     type="text" class="form-control" [(ngModel)]="singleSelectedMoyen.type"
                     (ngModelChange)="filtre_type(singleSelectedMoyen.type, singleSelectedMoyen.categorie)"
                     (click)="filtre_type(singleSelectedMoyen.type,singleSelectedMoyen.categorie)"
                     (blur)="blur('type');"/>
              <div *ngIf="(tabFiltre.type != null && tabFiltre.type.length > 0) && bool_filtre.type"
                   class="list-propositions dynamic-search-input">
                <button type="button" class="list-group-item item" *ngFor="let type of tabFiltre.type"
                        (click)="select('type',type)">
                  {{ type }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-lg-6 div-modal">
            <label>Matière</label>
            <div class="form-group position-relative">
              <input [disabled]="!singleSelectedMoyen.idSite" type="text" class="form-control"
                     [(ngModel)]="singleSelectedMoyen.matiere"
                     (ngModelChange)="filtre('matiere', singleSelectedMoyen.matiere)"
                     (click)="filtre('matiere',singleSelectedMoyen.matiere)"
                     (blur)="blur('matiere');"/>
              <div
                *ngIf="(tabFiltre.matiere != null && tabFiltre.matiere.length > 0) && bool_filtre.matiere"
                class="list-propositions dynamic-search-input">
                <button type="button" class="list-group-item item"
                        *ngFor="let matiere of tabFiltre.matiere"
                        (click)="select('matiere',matiere)">
                  {{ matiere }}
                </button>
              </div>
            </div>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>DN</label><input [disabled]="!singleSelectedMoyen.idSite" class="form-control"
                                    type="text" [(ngModel)]="singleSelectedMoyen.dn"/>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>Dimension</label><input [disabled]="!singleSelectedMoyen.idSite"
                                           class="form-control" type="text"
                                           [(ngModel)]="singleSelectedMoyen.dimension"/>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>Commentaire</label><input [disabled]="!singleSelectedMoyen.idSite"
                                             class="form-control" type="text"
                                             [(ngModel)]="singleSelectedMoyen.commentaire"/>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>Code_ref</label><input [disabled]="!singleSelectedMoyen.idSite"
                                          class="form-control" type="text"
                                          [(ngModel)]="singleSelectedMoyen.code_ref"/>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>Désignation</label><input [disabled]="!singleSelectedMoyen.idSite"
                                             class="form-control" type="text"
                                             [(ngModel)]="singleSelectedMoyen.designation"/>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>Libellé</label><input [disabled]="!singleSelectedMoyen.idSite" class="form-control"
                                         type="text" [(ngModel)]="singleSelectedMoyen.libelle"/>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>Prix d'achat</label><input [disabled]="!singleSelectedMoyen.idSite" class="form-control"
                                         type="number" [(ngModel)]="singleSelectedMoyen.prix_achat"/>
          </div>
          <div class="form-group col-lg-6 div-modal">
            <label>Prix de vente</label><input [disabled]="!singleSelectedMoyen.idSite" class="form-control"
                                         type="number" [(ngModel)]="singleSelectedMoyen.prix_vente"/>
          </div>
        </div>
      </div>
      <div *ngIf="showError" class="alert alert-danger" role="alert">{{ errorTxt }}</div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="validate()">Valider</button>
        <button class="btn btn-warning" (click)="dismiss()">Annuler</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
