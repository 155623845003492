import {
  Component,
  OnInit,
  Input,
  forwardRef,
  Output, EventEmitter, ViewChild,
} from '@angular/core';
import {
  NgbTimeStruct,
  NgbDateStruct,
  NgbPopoverConfig,
  NgbPopover,
  NgbDatepicker,
} from '@ng-bootstrap/ng-bootstrap';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DateTimeModel } from './date-time.model';
import { noop } from 'rxjs';
import {NbToastrService} from '@nebular/theme';
import {Destroy} from '../../../@core/services/destroy.service';
import {take, takeUntil} from 'rxjs/operators';
import {NewmDialogService} from '../../../@core/services/newm-dialog.service';
import {DateTimePickerModalComponent} from './modal/date-time-picker-modal.component';
import { DateTimePickerFormatEnum } from '../../../@core/models/enum/dateTimePickerFormat.enum';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    DatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    Destroy,
    NgbPopover,
  ],
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit {

  @Input()
  dateString: string;

  @Input()
  inputDatetimeFormat: string = 'dd/MM/yyyy HH:mm';

  @Input()
  hourStep: number = 1;
  @Input()
  minuteStep: number = 1;
  @Input()
  secondStep: number = 30;
  @Input()
  seconds: boolean = false;

  @Input()
  public set min(val: Date) {
    if (val) {
      this.setMinDateTime(val);
    }
  }
  @Input()
  public set max(val: Date) {
    if (val) {
      this.setMaxDateTime(val);
    }
  }

  @Input()
  disabled: boolean = false;
  @Input()
  withTimePicker: boolean = true;
  @Input()
  withIcon: boolean = true;
  @Input()
  withValidateButton: boolean = false;
  @Input()
  withCancelButton: boolean = false;
  @Input()
  withEraseButton: boolean = false;

  @Input()
  inputClass: string = '';

  @Input()
  dateTimePickerFormat: DateTimePickerFormatEnum = DateTimePickerFormatEnum.POPOVER;

  @Output() buttonSaveClick: EventEmitter<void> = new EventEmitter<void>();

  protected datetime: DateTimeModel = new DateTimeModel();
  private firstTimeAssign: boolean = true;
  protected minDate: NgbDateStruct;
  private minTime: NgbTimeStruct;
  protected maxDate: NgbDateStruct;
  private maxTime: NgbTimeStruct;

  private isEqualsToMinDate: boolean = false;
  private isEqualsToMaxDate: boolean = false;

  private isUnderMinTime: boolean = false;
  private isOverMaxTime: boolean = false;

  private onTouched: () => void = noop;
  private onChange: (_: any) => void = noop;

  constructor(private readonly config: NgbPopoverConfig,
              private readonly alertService: NbToastrService,
              private readonly newmDialogService: NewmDialogService,
              private readonly destroy$: Destroy) {
    config.autoClose = 'outside';
    config.placement = 'auto';
  }

  @ViewChild('popover') popover: NgbPopover;

  ngOnInit(): void {
    if (!this.withTimePicker) {
      this.inputDatetimeFormat = 'dd/MM/yyyy';
    }
  }
  initializeDate(): void {
    if (this.dateString) {
      this.datetime = Object.assign(
        this.datetime,
        DateTimeModel.fromLocalString(this.dateString),
      );
      this.isEqualsToMinDate = this.equalsDate(this.datetime, this.minDate);
      this.isEqualsToMaxDate = this.equalsDate(this.datetime, this.maxDate);
      this.isUnderMinTime = this.time1UnderTime2(this.datetime, this.minTime);
      this.isOverMaxTime = this.time1OverTime2(this.datetime, this.maxTime);
    }
  }

  writeValue(newModel: string): void {
    if (newModel) {
      this.datetime = Object.assign(
        this.datetime,
        DateTimeModel.fromLocalString(newModel),
      );
      this.dateString = newModel;
      this.setDateStringModel();
    } else {
      this.buttonEraseDate();
    }
  }

  openModal() {
    this.newmDialogService.openDialog(
      DateTimePickerModalComponent,
      {size: '100px', centered: true, backdrop: 'static', keyboard: true, modalDialogClass: 'modaldtpicker', windowClass: 'modaldtpicker'},
      {
        datetime: this.datetime,
        minDate: this.minDate,
        minTime: this.minTime,
        maxDate: this.maxDate,
        maxTime: this.maxTime,
        withTimePicker: this.withTimePicker,
        hourStep: this.hourStep,
        minuteStep: this.minuteStep,
        secondStep: this.secondStep,
        seconds: this.seconds,
        withEraseButton: this.withEraseButton,
      },
    ).closed
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe({
      next: (result: DateTimeModel) => {
        if (result) {
          this.onDateChange(result, null);
          this.onTimeChange(result);
          if (this.dateTimePickerFormat === DateTimePickerFormatEnum.MODAL) {
            this.buttonSaveDate(result);
          }
        }
      },
    });
  }

  setMinDateTime(min: Date): void {
    const date = new Date(this.parseFixStringDate(min));
    this.minDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
    this.minTime = {hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };
  }

  setMaxDateTime(max: Date): void {
    const date = new Date(this.parseFixStringDate(max));
    this.maxDate = {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
    this.maxTime = {hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputChange($event: any) {
    const value = $event.target.value;
    const dt = DateTimeModel.fromLocalString(value);

    if (dt) {
      this.datetime = dt;
      this.setDateStringModel();
    } else if (value.trim() === '') {
      this.datetime = new DateTimeModel();
      this.dateString = '';
      this.onChange(this.dateString);
    } else {
      this.onChange(value);
    }
  }

  checkForDate(event: NgbDateStruct, dp: NgbDatepicker): void {
    if (event != null) {
      if (this.minDate || this.maxDate) {
        this.isEqualsToMinDate = this.equalsDate(event, this.minDate);
        this.isEqualsToMaxDate = this.equalsDate(event, this.maxDate);

        // si c'est seulement un datepicker
        if (!this.withTimePicker) {
          this.onDateChange(event, dp);
         // si il y a un timepicker
        } else {
          if (this.isUnderMinTime === undefined || this.isOverMaxTime === undefined) {
            this.isUnderMinTime = this.time1UnderTime2(this.datetime, this.minTime);
            this.isOverMaxTime = this.time1OverTime2(this.datetime, this.maxTime);
          }
          if ((this.isEqualsToMinDate && this.isUnderMinTime) || (this.isEqualsToMaxDate && this.isOverMaxTime)) {
            this.alertService.danger('La date et l\'heure doivent être supérieures à la date et l\'heure minimales et inférieures à la date et l\'heure maximales');
          } else {
            this.onDateChange(event, dp);
          }
        }
      } else {
        this.onDateChange(event, dp);
      }
    }
  }

  checkForTime(event: NgbTimeStruct): void {
    if (event != null) {
      if (this.minTime || this.maxTime) {
        const tempTimeStruct: NgbTimeStruct = {hour: event.hour, minute: event.minute, second: event.second};

        this.isUnderMinTime = this.time1UnderTime2(tempTimeStruct, this.minTime);
        this.isOverMaxTime = this.time1OverTime2(tempTimeStruct, this.maxTime);
        if (this.isEqualsToMinDate === undefined || this.isEqualsToMaxDate === undefined) {
          this.isEqualsToMinDate = this.equalsDate(this.datetime, this.minDate);
          this.isEqualsToMaxDate = this.equalsDate(this.datetime, this.maxDate);
        }
        if ((this.isEqualsToMinDate && this.isUnderMinTime) || (this.isEqualsToMaxDate && this.isOverMaxTime)) {
          this.alertService.danger('La date et l\'heure doivent être supérieures à la date et l\'heure minimales et inférieures à la date et l\'heure maximales')
        } else {
          this.onTimeChange(event);
        }
      } else {
        this.onTimeChange(event);
      }
    }
  }

  onDateChange($event: string | NgbDateStruct, dp: NgbDatepicker): void {
    const date: DateTimeModel = new DateTimeModel($event);

    if (!date) {
      // This seems like an attempt to trigger change detection
      this.dateString = this.dateString.concat('');
      return;
    }

    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;

    const adjustedDate = new Date(this.datetime.toString());
    if (this.datetime.timeZoneOffset !== adjustedDate.getTimezoneOffset()) {
      this.datetime.timeZoneOffset = adjustedDate.getTimezoneOffset();
    }
    // si on a juste un datepicker, on ferme le popover automatiquement à la sélection d'une date
    if (this.dateTimePickerFormat === DateTimePickerFormatEnum.POPOVER && !this.withTimePicker) {
      this.popover.close();
    }
    this.setDateStringModel();
  }

  onTimeChange(event: NgbTimeStruct): void {
      this.datetime.hour = event.hour;
      this.datetime.minute = event.minute;
      this.setDateStringModel();
  }

  equalsDate(date1: NgbDateStruct, date2: NgbDateStruct): boolean {
    if (date1 && date2) {
      const tempDate1: Date = new Date(date1.year, date1.month, date1.day);
      const tempDate2: Date = new Date(date2.year, date2.month, date2.day);
      return tempDate1.getTime() === tempDate2.getTime();
    }
  }


  time1UnderTime2(time1: NgbTimeStruct, time2: NgbTimeStruct): boolean {
      return time1 && time2 && time1.hour <= time2.hour && time1.minute <= time2.minute;
  }

  time1OverTime2(time1: NgbTimeStruct, time2: NgbTimeStruct): boolean {
    return time1 && time2 && time1.hour >= time2.hour && time1.minute >= time2.minute;
  }

  setDateStringModel(): void {
    this.dateString = this.datetime.toString();
    if (!this.firstTimeAssign) {
      this.onChange(this.dateString);
    } else {
      if (this.dateString !== null) {
        this.firstTimeAssign = false;
        this.onChange(this.dateString);
      }
    }
  }

  inputBlur($event): void {
    this.onTouched();
  }

  parseFixStringDate(str): string {
    if (str != null) {
      try {
        const cleanedStr: string = str.replace(/\.\d+|(\+\d{2})\[.*\]|(\+\d{2}:\d{2}\[.*\])|(\[.*\])$/g, '');
        return cleanedStr;
      } catch (TecniqueException) {
        return str;
      }
    }
    return null;
  }

  buttonSaveDate(event?: any): void {
    if (event) {
      event.datetime = this.datetime;
      event.datestring = this.dateString;
    }
    this.buttonSaveClick.emit(event ? event : null);
    if (this.dateTimePickerFormat === DateTimePickerFormatEnum.POPOVER) {
      this.popover.close();
    }
  }

  buttonEraseDate(): void {
    this.datetime = new DateTimeModel();
    this.dateString = '';
    this.onChange(this.dateString);
  }

  protected readonly DateTimePickerFormatEnum = DateTimePickerFormatEnum;
}
