import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

export interface ConfModel {
  tabImages: any[],
  imgDescription: any;
}


@Component({
  selector: 'ngx-modale-consultation-photo',
  templateUrl: './modale-consultation-photo.component.html',
  styleUrls: ['./modale.scss'],
})

export class ModaleConsultationPhotoComponent implements ConfModel, OnInit {

  public tabImages: any[];
  public imgDescription: any;
  public actualImgSrc: string;
  public showChevronLeft: boolean;
  public showChevronRight: boolean;
  public index: number;

  ngOnInit(): void {
    this.index = 0;
    this.showChevronLeft = false;
    this.showChevronRight = this.tabImages.length > 1;
    this.actualImgSrc = environment.apiPath + 'document/getMachinesDoc/' + this.tabImages[this.index].mPath;
  }

  public clickChevronLeft(): void {
    if (this.index - 1 >= 0) {
      this.index = this.index - 1;
      this.showChevronRight = true;
      this.actualImgSrc = environment.apiPath + 'document/getMachinesDoc/' + this.tabImages[this.index].mPath;
      this.showChevronLeft = this.index !== 0;
    }
  }

  public clickChevronRight(): void {
    if (this.index + 1 < this.tabImages.length) {
      this.index = this.index + 1;
      this.showChevronLeft = true;
      this.actualImgSrc = environment.apiPath + 'document/getMachinesDoc/' + this.tabImages[this.index].mPath;
      this.showChevronRight = this.index !== this.tabImages.length - 1;
    }
  }

  public dismiss(): void {
    // this.close();
  }
}
