<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modaleDelLabel">Confirmation de la suppression</h5>
      <button type="button" class="btn-close" aria-label="Close" style="margin-left: auto;" (click)="dismiss()"></button>
    </div>
    <div *ngIf="del_moyen" class="modal-body">
      Êtes-vous certain de vouloir supprimer {{single_moyen ? "ce moyen" : "ces moyens"}} ?
    </div>
    <div *ngIf="!del_moyen" class="modal-body">
      Êtes-vous certain de vouloir supprimer cette récurrence ?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="dismiss()">Annuler</button>
      <button type="button" class="btn btn-primary" (click)="validate()" >OK</button>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
