import {Component, Input, OnInit, signal, WritableSignal} from '@angular/core';
import {ParametreEnum} from '../../../@core/models/enum/parametre.enum';
import {FormControl} from '@angular/forms';
import {ParametreService} from '../../../@core/services/parametre.service';
import {ParametreModel} from '../../../@core/models/parametre.model';
import {TypeFicheExpressionBesoinEnum} from '../../../@core/models/enum/TypeFicheExpressionBesoin.enum';

@Component({
  selector: 'select-parametres',
  templateUrl: './select-parametres.component.html',
  styleUrls: ['./select-parametres.component.scss'],
})
export class SelectParametresComponent implements OnInit {
  @Input()
  name: string = null;

  @Input({required: true})
  typeParametre: ParametreEnum;

  @Input({required: true})
  idUsine: number;

  @Input({required: true})
  formControlNumber: FormControl<number>;

  @Input()
  formControlOther: FormControl<string | null>;

  listOfParametres: WritableSignal<ParametreModel[]> = signal([]);

  constructor(private readonly parametreService: ParametreService) {
  }

  ngOnInit() {
    this.parametreService.getListParametreByTypeAndUsine(this.typeParametre, this.idUsine).subscribe((result) => {
      this.listOfParametres.set(result);

      if (this.formControlNumber.value === null) {
        const parametre = result.find(param => param.valeurDefaut);
        this.formControlNumber.patchValue(parametre ? parametre.id : null);
      }
    });

    if (this.name === null) {
      this.name = 'item de la liste';
    }
  }

  protected readonly TypeFicheExpressionBesoinEnum = TypeFicheExpressionBesoinEnum;
}
