import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {TreeNode} from "../../../@core/models/treenode.model";

@Component({
  selector: 'app-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.css']
})
export class TreeviewComponent implements OnInit {

  @Input() nodes: TreeNode[] = [];

  ngOnInit(): void {
    if (this.nodes) {
      this.nodes.forEach(node => {
        if (node.children) {
          node.checked = this.allChildrenChecked(node);
          this.setParents(node, null);
        }
      });
    }
  }

  onCheckboxChange(node: any): void {
    node.checked = !node.checked;
    if (node.children) {
      this.toggleChildren(node);
      this.setParents(node, null);
    }
    if (node.parent) {
      node.parent.checked = this.allChildrenChecked(node.parent);
    }
  }

  private allChildrenChecked(node: any): boolean {
    return node?.children?.every((child: any) => child.checked);
  }

  private toggleChildren(node: any): void {
    node.children.forEach((child: any) => {
      child.checked = node.checked;
      if (child.children) {
        this.toggleChildren(child);
      }
    });
  }

  private setParents(node: any, parent: any): void {
    node.parent = parent;
    if (node.children) {
      node.children.forEach((child: any) => this.setParents(child, node));
    }
  }

  public getAllNodesFromTree(): TreeNode[] {
    return this.getAllNodes(this.nodes);
  }

  private getAllNodes(nodes: TreeNode[]): TreeNode[] {
    let tempTree: TreeNode[]= [];
    tempTree.push(...nodes);
    tempTree.push(...nodes
      .filter(node => node.children && node.children.length > 0)
      .map(node => this.getAllNodes(node.children))
      .reduce((previousValue, currentValue) => previousValue.concat(currentValue), [])
    )
    return tempTree;
  }

  public getAllNodesCheckedFromTree(): TreeNode[] {
    return this.getAllNodesChecked(this.nodes);
  }

  private getAllNodesChecked(nodes: TreeNode[]): TreeNode[] {
    let tempTree: TreeNode[]= [];
    nodes.forEach(node => {
      if (node.checked) {
        tempTree.push(node);
      }
      if (node.children && node.children.length > 0) {
        tempTree = tempTree.concat(this.getAllNodesChecked(node.children));
      }
    });
    return tempTree;
  }

  isIndeterminate(node: any): boolean {
    return !node.checked && node.children?.some((child: any) => child.checked);
  }

  private toggleNode(node: TreeNode): void {
    node.expanded = !node.expanded;
  }

  isNodeExpanded(node: TreeNode): boolean {
    return node.expanded || false;
  }

  hasChildren(node: TreeNode): boolean {
    return node.children && node.children.length > 0;
  }
}
