export class UserProfileFilterModel {

  pageSize: number;
  page: number;
  code: string;
  libelle: string;
  description: string;
  actif: boolean;
  admin: Boolean;
  dateCreation: Date;
  dateMaj: Date;


}
