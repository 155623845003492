import {Component, OnInit, ViewChild} from '@angular/core';
import {UserDroitFilterModel} from '../../../../../@core/models/user/userDroitFilter.model';
import {UserDroitModel} from '../../../../../@core/models/user/userDroit.model';
import {DroitService} from '../../../../../@core/services/users/droit.service';
import {UserModel} from '../../../../../@core/models/user/user.model';
import {BusinessUnitModel} from '../../../../../@core/models/businessUnit';
import {ProfileService} from '../../../../../@core/services/users/profile.service';
import {UserProfileFilterModel} from '../../../../../@core/models/user/userProfileFilter.model';
import {UserProfileModel} from '../../../../../@core/models/user/userProfile.model';
import {UserDroitEnum} from '../../../../../@core/models/enum/userDroit.enum';
import {UserAffectationModel} from '../../../../../@core/models/user/userAffectation.model';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TreeNode} from "../../../../../@core/models/treenode.model";
import {CreateTreeNode} from "../../../../../@core/models/createTreeNode.model";

export interface ConfModel {
  selectedUser: UserModel,
  businessUnits: any,
  currentUser: UserModel,
}

@Component({
  selector: 'ngx-gestion-moyens-modale-edition-user',
  templateUrl: './modale-edition-user.component.html',
  styleUrls: ['../modale.scss'],
  providers: [CreateTreeNode,]
})

export class ModaleEditionUserComponent implements ConfModel, OnInit {
  public usines: any;
  public selectedUser: UserModel;
  public currentUser: UserModel;
  public businessUnits;
  public tabFiltre: any;
  public showError: boolean;
  public errorTxt: string;
  public listProfils: UserProfileModel[];
  public droitGestionUtilisateurModifPrenom: boolean;
  public droitGestionUtilisateurModifNom: boolean;
  public droitGestionUtilisateurModifEmail: boolean;
  public droitGestionUtilisateurModifPwd: boolean;
  public droitGestionUtilisateurModifProfils: boolean;
  public droitGestionUtilisateurModifEtat: boolean;
  public droitGestionUtilisateurModifDroits: boolean;
  public droitGestionUtilisateurModifUsines: boolean;
  public droitGestionUtilisateurSuppression: boolean;
  public nodesDroits: TreeNode[];
  public nodesUsines: TreeNode[];

  constructor(private readonly droitService: DroitService,
              private readonly profilService: ProfileService,
              public readonly activeModal: NgbActiveModal,
              public readonly createTreeNode: CreateTreeNode
  ) {
  }

  public ngOnInit(): void {
    this.droitGestionUtilisateurModifPrenom = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_PRENOM);
    this.droitGestionUtilisateurModifNom = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_NOM);
    this.droitGestionUtilisateurModifEmail = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_EMAIL);
    this.droitGestionUtilisateurModifPwd = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_PASSWORD);
    this.droitGestionUtilisateurModifProfils = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_PROFILS);
    this.droitGestionUtilisateurModifEtat = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_RAPIDE_ETAT);
    this.droitGestionUtilisateurModifDroits = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_DROITS);
    this.droitGestionUtilisateurModifUsines = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_MODIFICATION_USINE);
    this.droitGestionUtilisateurSuppression = this.hasDroit(UserDroitEnum.GESTION_UTILISATEUR_SUPPRESSION);
    this.nodesDroits = [];
    this.nodesUsines = this.createTreeNode.createTree(this.businessUnits['dataSource'], 'usine');

    const lUserProfileFilter = new UserProfileFilterModel();
    lUserProfileFilter.actif = true;
    lUserProfileFilter.admin = this.selectedUser.profile.admin;
    this.profilService.getListProfile(lUserProfileFilter).subscribe(
      (data) => {
        this.listProfils = data;
      },
    );
    this.selectedUser.listAffectation = this.businessUnits['dataSource'];
    this.selectedUser.password = '';

    this.tabFiltre = [];
    this.showError = false;
    this.errorTxt = '';
    if (!this.selectedUser.listUsines) {
      this.selectedUser.listUsines = [];
    }
    if (!this.selectedUser.profile) {
      this.selectedUser.profile = new UserProfileModel();
    }
    this.loadDroits();
  }

  hasDroit(droit) {
    if (this.selectedUser.profile.admin === true) {
      if (this.currentUser.profile.admin !== true) {
        return false;
      }
    }
    return this.currentUser.hasDroit(droit);
  }

  public validate(): void {
    if (this.selectedUser.profile.id === -1) {
      this.errorTxt = 'Veuillez sélectionner un profil';
      this.showError = true;
    } else if (this.droitGestionUtilisateurModifUsines
      && this.treeViewUsine.getAllNodesCheckedFromTree().length < 1) {
      this.errorTxt = 'Veuillez affecter une usine';
      this.showError = true;
    } else {
      let filterDroit;
      let tempTreeDroit: TreeNode[] = this.treeViewDroit.getAllNodesFromTree();
      if ((this.treeViewDroit && tempTreeDroit && tempTreeDroit.length > 0 &&
        tempTreeDroit.length === this.selectedUser.listDroit.length) || this.selectedUser.id == null) {
        filterDroit = this.treeViewDroit.getAllNodesFromTree().filter(droit => droit.checked);
      } else {
        filterDroit = this.selectedUser.listDroit.filter(droit => droit.checked);
      }
      this.selectedUser.listDroit = [];
      filterDroit.forEach(droitFilter => {
        this.selectedUser.listDroit.push(new UserDroitModel().fromNodes(droitFilter))
      });
      let filterAffectation;
      let tempTreeUsines: TreeNode[] = this.treeViewUsine.getAllNodesFromTree();
      if (this.treeViewUsine && tempTreeUsines && tempTreeUsines.length > 0 || this.selectedUser.id === null) {
        filterAffectation = tempTreeUsines.filter(affectation => affectation.checked);
      } else {
        filterAffectation = this.selectedUser.listAffectation.filter(affectation => affectation.checked);
      }
      this.selectedUser.listAffectation = [];
      filterAffectation.forEach(affectationFilter => {
        this.selectedUser.listAffectation.push(new UserAffectationModel().fromNodes(affectationFilter))
      });
      this.showError = false;
      this.activeModal.close(this.selectedUser);
    }
  }

  public dismiss(): void {
    this.activeModal.close(null);
  }

  public select(champ: string, text: string): void {
    this.selectedUser[champ] = text;
    this.tabFiltre[champ] = [];
  }

  private loadDroits() {
    const lUserdroitFilter = new UserDroitFilterModel();
    lUserdroitFilter.idUser = this.selectedUser.id;
    lUserdroitFilter.all = true;
    this.droitService.getListDroit(lUserdroitFilter).subscribe(
      (data: Array<UserDroitModel>) => {
          this.nodesDroits = this.createTreeNode.createTree(data, 'droit');
       });
  }

  public setActif(active: boolean) {
    this.selectedUser.active = active;
  }

  public updateListeUsineForUser(isChecked: boolean, usineId: number, usineNom: string): void {
    if (isChecked) {
      const usine = new BusinessUnitModel();
      usine.id = usineId;
      usine.nom = usineNom;
      let isIn: boolean = false;
      this.selectedUser.listUsines.forEach((singleUsine) => {
        if (usine.id === singleUsine.id) {
          isIn = true;
        }
      });
      if (!isIn) {
        this.selectedUser.listUsines.push(usine);
      }
    } else {
      let usineToDelete: BusinessUnitModel;
      this.selectedUser.listUsines.forEach((singleUsine) => {
        if (usineId === singleUsine.id) {
          usineToDelete = singleUsine;
        }
      });
      if (usineToDelete) {
        this.selectedUser.listUsines.splice(this.selectedUser.listUsines.indexOf(usineToDelete), 1);
      }
    }
  }

  public setDroitByProfil() {
    this.profilService.getProfile(this.selectedUser.profile.id).subscribe(
      (data: UserProfileModel) => {
        this.selectedUser.profile = data;
      });

    const lUserdroitFilter = new UserDroitFilterModel();
    lUserdroitFilter.idProfile = this.selectedUser.profile.id;
    lUserdroitFilter.all = true;
    this.droitService.getListDroit(lUserdroitFilter).subscribe(
      (data: Array<UserDroitModel>) => {
        this.selectedUser.listDroit = data;
        this.nodesDroits = this.createTreeNode.createTree(data, 'droit');
      });
  }

  deleteUser(pUser) {
    // this.result = pUser;
    // this.result.delete = true;
    // this.close();
  }

  @ViewChild('treeViewDroit', {static: false})
  public treeViewDroit;
  public droitField: TreeNode;
  public allowEditing: boolean = false;
  public autoCheckParentNode: boolean = false;
  public autoCheck: boolean = true;

  @ViewChild('treeViewUsine', {static: false})
  public treeViewUsine;

}
