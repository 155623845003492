import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {ParametreModel} from '../models/parametre.model';
import {ParametreFitlerDTO} from '../models/filtreParametre.model';
import {HttpClient} from '@angular/common/http';
import {Pageable} from '../models/pageable.model';
import {ParametreEnum} from '../models/enum/parametre.enum';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ParametreService {

  private readonly parametreTypeCache: Map<ParametreEnum, ParametreModel[]> = new Map<ParametreEnum, ParametreModel[]>();

  constructor(private readonly http: HttpClient) {
  }

  getListParametre(paramsDTO: ParametreFitlerDTO): Observable<Pageable<ParametreModel>> {
    return this.http.post<Pageable<ParametreModel>>(environment.apiPathV2 + 'parametre/filter', paramsDTO);
  }

  getParametreByTypes(type: ParametreEnum): Observable<ParametreModel[]> {

    if (this.parametreTypeCache.has(type)) {
      return of(this.parametreTypeCache.get(type));
    }

    return this.http.get<ParametreModel[]>(`${environment.apiPathV2}parametre/${type}`)
      .pipe(tap(parametres => {
        if (
          [ParametreEnum.DIMENSION_ECHAFAUDAGE, ParametreEnum.DIMENSION_CALORIFUGEAGE, ParametreEnum.DIMENSION_NETTOYAGE].includes(type)) {
          this.parametreTypeCache.set(type, parametres);
        }
      }));
  }

  getListParametreByTypeAndUsine(type: ParametreEnum, idUsine: number): Observable<ParametreModel[]> {
    return this.http.get<ParametreModel[]>(`${environment.apiPathV2}parametre/${type}/${idUsine}`);
  }

  updateParametre(params: ParametreModel): Observable<ParametreModel> {
    return this.http.put<ParametreModel>(environment.apiPathV2 + 'parametre', params);
  }

  createParametre(params: ParametreModel): Observable<ParametreModel> {
    return this.http.post<ParametreModel>(environment.apiPathV2 + 'parametre/create', params)
  }

  deleteParametre(id: number): Observable<any> {
    return this.http.delete(environment.apiPathV2 + 'parametre/' + id)
  }

  getListParametreType(): Observable<string[]> {
    return this.http.get<string[]>(environment.apiPathV2 + 'parametre/parametre-type');
  }

  public clearCache(): void {
    this.parametreTypeCache.clear();
  }
}
