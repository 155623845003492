<div class="modal-dialog" role="dialog" id="videoModale">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Video</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
    </div>
    <div class="modal-body">
      <a href="api/machines/file/{{video.mPath}}">Télécharger</a>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ok()">Quitter</button>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
