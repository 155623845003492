import {Component} from '@angular/core';

export interface ConfModel {
  del_moyen: boolean,
  single_moyen: boolean,
}

@Component({
  selector: 'ngx-gestion-moyens-modale-del',
  templateUrl: './modale-suppression.component.html',
  styleUrls: ['../../../../modale/modale.scss'],
})

export class ModaleSupressionComponent implements ConfModel {

  public del_moyen: boolean;
  public single_moyen: boolean;

  public validate(): void {
    // this.result = true;
    // this.close();
  }

  public dismiss(): void {
    // this.close();
  }
}
