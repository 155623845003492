import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import { HandlerOption, handleServerError, handleServerResponse } from '../directives/server-response-handler';
import { NbToastrService } from '@nebular/theme';
import { NgbToastOptions } from '@ng-bootstrap/ng-bootstrap/toast/toast-config';
import { AUTH_TOKEN_V1_STORAGE_KEY } from '../auth.guard';

@Injectable({providedIn: 'root'})
export class BaseService {

  private get defaultHttpPostOptions() {
    return {
      headers: this.createAuthorizationHeader()
    }
  }

  constructor(
      protected readonly http: HttpClient,
      protected readonly toastr?: NbToastrService,
  ) {}

  createAuthorizationHeader(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json, application/octet-stream');
    headers = headers.append('Content-Type', 'application/json');
    if (localStorage.getItem(AUTH_TOKEN_V1_STORAGE_KEY) != null) {
      headers = headers.append(AUTH_TOKEN_V1_STORAGE_KEY, localStorage.getItem(AUTH_TOKEN_V1_STORAGE_KEY));
    }
    return headers;
  }

  protected get<T>(url: any) {
    return this.http.get(url, {
      headers: this.createAuthorizationHeader(),
    });
  }

  protected post<T>(url: string, data: any, toastResponse = false, options?: HandlerOption): Observable<T> {
    if (toastResponse) {
      return this.http.post<T>(url, data, {
        headers: this.createAuthorizationHeader(),
        observe: "response"
      }).pipe(handleServerResponse(this.toastr, options));
    } else {
      return this.http.post<T>(url, data, {
        headers: this.createAuthorizationHeader(),
      });
    }

  }

  protected postFile(url: any, data: any) {
    return this.http.post(url, data);
  }

  protected put<T>(url: any, data: any, toastResponse = false, options?: HandlerOption): Observable<T> {
    if (toastResponse) {
      return this.http.put<T>(url, data, {
        headers: this.createAuthorizationHeader(),
        observe: "response"
      }).pipe(handleServerResponse(this.toastr, options));
    } else {
      return this.http.put<T>(url, data, {
        headers: this.createAuthorizationHeader(),
      });
    }
  }

  delete(url: any, toastResponse = false, options?: HandlerOption) {
    if (toastResponse) {
      return this.http.delete(url, {
        headers: this.createAuthorizationHeader(),
        observe: "response"
      }).pipe(handleServerResponse(this.toastr, options));
    } else {
      return this.http.put(url, {
        headers: this.createAuthorizationHeader(),
      });
    }
  }
}
