import {TreeNode} from "../treenode.model";

export class UserDroitModel {

  id: number;
  code: string;
  libelleCourt: string;
  libelleLong: string;
  description: string;
  idParent: number;
  dateCreation: Date;
  dateMaj: Date;
  isDelete: boolean;
  checked: boolean;



  fromNodes(droitFilter: any) {
    this.id = droitFilter.id;
    this.code = droitFilter.code;
    this.libelleCourt = droitFilter.name;
    this.libelleLong = droitFilter.libelleLong;
    this.description = droitFilter.description;
    this.idParent = droitFilter.idParent;
    this.dateCreation = droitFilter.dateCreation;
    this.dateMaj = droitFilter.dateMaj;
    this.isDelete = droitFilter.isDelete;
    this.checked = droitFilter.checked;
    return this;
  }

}
