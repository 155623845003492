import { Pipe, PipeTransform } from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

@Pipe({
  name: 'getFormControlNumber',
})
export class GetFormControlNumberPipe implements PipeTransform {

  transform(value: FormGroup, formControlName: string): FormControl {
    if (!value || !formControlName) {
      return new FormControl(-1);
    }

    return value.get(formControlName) as FormControl;
  }
}
