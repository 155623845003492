import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {BaseService} from '../base.service';
import {UserProfileModel} from '../../models/user/userProfile.model';
import {UserProfileFilterModel} from '../../models/user/userProfileFilter.model';

@Injectable({providedIn: 'root'})
export class ProfileService extends BaseService {

  private url: string = `profiles/`;

  updateProfile(user: UserProfileModel): Observable<number> {
    return this.post(environment.apiPath + this.url + 'update', user).pipe(
      map(item => {
        return item as number;
      }),
    );
  }

  replaceProfile(user: UserProfileModel): Observable<number> {
    return this.post(environment.apiPath + this.url + 'replace', user).pipe(
      map(item => {
        return item as number;
      }),
    );
  }

  insertProfile(user: UserProfileModel): Observable<number> {
    return this.post(environment.apiPath + this.url, user).pipe(
      map(item => {
        return item as number;
      }),
    );
  }

  deleteProfile(id: number): Observable<boolean> {
    return this.delete(environment.apiPath + this.url + 'delete/' + id).pipe(
      map(item => {
        return true;
      }),
    );
  }

  getListProfile(filter: UserProfileFilterModel): Observable<Array<UserProfileModel>> {
    return this.post(environment.apiPath + this.url + 'list', filter).pipe(
      map(item => {
        return item['profiles'] as Array<UserProfileModel>;
      }),
    );
  }

  getProfile(idProfile: number): Observable<any> {
    return this.get(environment.apiPath + this.url + 'get/' + idProfile).pipe(
      map(item => {
        return item;
      }),
    );
  }
}
