import {TreeNode} from "../treenode.model";

export class UserAffectationModel {

  id: number;
  libelleCourt: string;
  idParent: number;
  checked: boolean;
  id_atelier: number;

  fromNodes(affectationFilter: any) {
    this.id = affectationFilter.id;
    this.libelleCourt = affectationFilter.name;
    this.idParent = affectationFilter.idParent;
    this.checked = affectationFilter.checked;
    this.id_atelier = affectationFilter.id_atelier;
    return this;
  }
}
