export enum ParametreEnum {
    GANTT_COLOR_BY_POSTE = 'GANTT_COLOR_BY_POSTE',
    P_AFFPREST_ACHAT_REFACTURE = 'P_AFFPREST_achat_refacture',
    P_AFFPREST_PARTIE_VARIABLE = 'P_AFFPREST_partie_variable',
    P_AFFPREST_PARTIE_FIXE = 'P_AFFPREST_partie_fixe',
    ACCESSOIRE_PROJECTION = 'ACCESSOIRE_PROJECTION',
    CALORIFUGE = 'CALORIFUGE',
    CLASS_CHARGE = 'CLASS_CHARGE',
    LIBRE_PASSAGE = 'LIBRE_PASSAGE',
    NATURE_APPUIS = 'NATURE_APPUIS',
    NATURE_ISOLANTS = 'NATURE_ISOLANTS',
    TRAVAUX_REALISER_CIRCUIT = 'TRAVAUX_REALISER_CIRCUIT',
    TYPE_CALORIFUGEAGE = 'TYPE_CALORIFUGEAGE',
    TYPE_ECHAFAUDAGE = 'TYPE_ECHAFAUDAGE',
    TYPE_LEVAGE = 'TYPE_LEVAGE',
    TYPE_NETTOYAGE = 'TYPE_NETTOYAGE',
    TYPE_REVETEMENT = 'TYPE_REVETEMENT',
    TYPE_ALIMENTATION_EAU = 'TYPE_ALIMENTATION_EAU',
    NATURE_PRODUIT = 'NATURE_PRODUIT',
    DIMENSION_NETTOYAGE = 'DIMENSION_NETTOYAGE',
    DIMENSION_CALORIFUGEAGE = 'DIMENSION_CALORIFUGEAGE',
    DIMENSION_ECHAFAUDAGE = 'DIMENSION_ECHAFAUDAGE',
}
