import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CoreModule} from './@core/core.module';
import {ThemeModule} from './@theme/theme.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ModaleSupressionComponent} from './pages/gestion/moyens/modales/modaleSupression/modale-supression.component';
import {ModaleImportComponent} from './pages/gestion/moyens/modales/modaleImport/modale-import.component';
import {ModaleAjoutMoyenComponent} from './pages/gestion/moyens/modales/modaleAjoutMoyen/modale-ajout-moyen.component';

import {ModaleEditionProfilComponent} from './pages/gestion/profil/modales/modaleEditionProfil/modale-edition-profil.component';
import {
  NbActionsModule,
  NbDatepickerModule,
  NbGlobalLogicalPosition,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbToastrModule,
} from '@nebular/theme';
import {ModaleConsultationPhotoComponent} from './pages/modale/modale-consultation-photo.component';
import {ModaleConfirmationComponent} from './pages/modale/modale-confirmation.component';
import {ModaleReportComponent} from './pages/operation/modals/modaleReport/modale-report.component';
import {ReportButtonComponent} from './pages/operation/report-button/report-button.component';
import {AtelierRendererComponent} from './pages/operation/atelierRenderer/atelier-renderer.component';
import {ModaleVideoComponent} from './pages/modale/modale-video.component';
import {AgGridModule} from 'ag-grid-angular';
import {SharedModule} from './shared/shared.module';
import {ModaleEditionUserComponent} from './pages/gestion/utilisateur/modales/modaleEditionUser/modale-edition-user.component';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {FormsModule} from '@angular/forms';
import {TreeviewComponent} from './@theme/components/treeview/treeview.component';
import {JwtTokenInterceptor} from './@core/interceptors/jwt.interceptor';
import {ErrorInterceptor} from './@core/interceptors/error.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    ModaleSupressionComponent,
    ModaleImportComponent,
    ModaleAjoutMoyenComponent,
    ModaleEditionProfilComponent,
    ModaleConsultationPhotoComponent,
    ModaleConfirmationComponent,
    ModaleReportComponent,
    ReportButtonComponent,
    AtelierRendererComponent,
    ModaleVideoComponent,
    ModaleEditionUserComponent,
    TreeviewComponent,
  ],
  imports: [
    NbEvaIconsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NbDatepickerModule.forRoot(),
    NbToastrModule.forRoot({
      position: NbGlobalLogicalPosition.BOTTOM_END,
      hasIcon: false,
      destroyByClick: true,
    }),
    AgGridModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    NbMenuModule.forRoot(),
    SharedModule,
    NbThemeModule.forRoot({name: 'corporate'}),
    NbLayoutModule,
    FormsModule,
    NbTabsetModule,
    NbIconModule,
    NbActionsModule,
    NbSidebarModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
})
export class AppModule {
}
