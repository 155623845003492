import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {UserModel} from '../../models/user/user.model';
import {BaseService} from '../base.service';
import {UserDroitModel} from '../../models/user/userDroit.model';
import {UserDroitFilterModel} from '../../models/user/userDroitFilter.model';

@Injectable({providedIn: 'root'})
export class DroitService extends BaseService {

  private url: string = `droits/`;

  getListDroit(filter: UserDroitFilterModel): Observable<Array<UserDroitModel>> {
    return this.post(environment.apiPath + this.url + 'list', filter).pipe(
      map(item => {
        return item as Array<UserDroitModel>;
      }),
    );
  }

  updateUser(user: UserModel): Observable<string> {
    return this.post(environment.apiPath + this.url + 'update', user).pipe(
      map(item => {
        return item as string;
      }),
    );
  }

  insertUser(user: UserModel): Observable<boolean> {
    return this.post(environment.apiPath + this.url, user).pipe(
      map(item => {
        return true
      }),
    );
  }

  deleteUser(id: number): Observable<boolean> {
    return this.delete(environment.apiPath + this.url + 'delete/' + id).pipe(
      map(item => {
        return true;
      }),
    );
  }
}
