import { Pipe, PipeTransform } from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';

@Pipe({
  name: 'getFormArray',
})
export class GetFormArrayPipe implements PipeTransform {

  constructor(private readonly fb: FormBuilder) {}

  transform(value: FormGroup, formArrayName: string): FormArray {
    if (!value || !formArrayName) {
      return this.fb.array([]);
    }

    return value.get(formArrayName) as FormArray;
  }

}
