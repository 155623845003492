<div class="modal-body">
  <div *ngIf="showError" class="alert alert-danger" role="alert">{{ errorTxt }}</div>
    <div class="text-center dtpicker">
      <ngb-datepicker id="dp" #dp name="datepicker"
                      [ngModel]="datetime"
                      [minDate]="minDate"
                      [maxDate]="maxDate"
                      (ngModelChange)="onDateChange($event)"
      ></ngb-datepicker>
      <div class="time-picker" *ngIf="withTimePicker">
        <ngb-timepicker #tp name="timepicker" class="justify-content-center"
                        [ngModel]="datetime"
                        [seconds]="seconds"
                        [hourStep]="hourStep"
                        [minuteStep]="minuteStep"
                        (ngModelChange)="onTimeChange($event)">
        </ngb-timepicker>
      </div>
    </div>
  <div class="mt-3 d-flex justify-content-center">
    <button *ngIf="withValidateButton" class="btn btn-primary" (click)="saveDate()">Enregistrer</button>
    <button *ngIf="withCancelButton" class="btn btn-warning" (click)="closeDialog()">Fermer</button>
    <button *ngIf="withEraseButton" class="btn btn-danger" >Effacer</button>
  </div>
</div>
