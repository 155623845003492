import { Observable, ReplaySubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

/**
 * Use this service to ensure subscriptions aren't creating memory leaks
 * @Source: https://dev.to/this-is-angular/dry-way-to-manage-subscriptions-in-angular-components-256j
 * @Author: Enzo Tamayo
 */
@Injectable()
export class Destroy extends Observable<void> implements OnDestroy {
  // initialize destroy subject
  private readonly destroySubject$ = new ReplaySubject<void>(1);

  constructor() {
    // emit destroy event to all subscribers when destroy subject emits
    super(subscriber => this.destroySubject$.subscribe(subscriber));
  }

  ngOnDestroy(): void {
    // emit destroy event when component that injects
    // `Destroy` provider is destroyed
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
