import { catchError, filter, map, MonoTypeOperatorFunction, OperatorFunction, pipe, tap, throwError } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ErrorDetails } from '../models/error-details.model';
import { NbToastrService } from '@nebular/theme';

export interface HandlerOption {
    errorMessage?: string
    successMessage?: string
}

const successTitle = 'Succès';
const errorTitle = 'Erreur';

export function handleServerResponse<T>(toasterService: NbToastrService, options?: HandlerOption)
    : OperatorFunction<HttpResponse<T>, T>{
    return pipe(
        _handleServerError(toasterService, options),
        _handleServerOk(toasterService, options),
        _mapToResponseType<T>(),
    );
}

export function handleServerError<T>(toasterService: NbToastrService, options?: HandlerOption)
    : OperatorFunction<HttpResponse<T>, T>{
    return pipe(
        _handleServerError(toasterService, options),
        _mapToResponseType<T>(),
    );
}

function _handleServerError<T extends HttpResponse<unknown>>(toasterService: NbToastrService, options?: HandlerOption): MonoTypeOperatorFunction<T> {
    return pipe(
        catchError((errorRequest: HttpErrorResponse) => {
            // Some http errors may or may not contain these details
            const details: Partial<ErrorDetails> = errorRequest.error;
            if(options?.errorMessage) {
                toasterService.danger(options.errorMessage, errorTitle);
            } else if(details?.message) {
                toasterService.danger(details.message, errorTitle)
            } else {
                // Fallback on native message
                toasterService.danger(errorRequest.message, errorTitle)
            }
            return throwError( () => errorRequest);
        })
    );
}

function _handleServerOk<T extends HttpResponse<unknown>>(toasterService: NbToastrService, options?: HandlerOption): MonoTypeOperatorFunction<T> {
    return pipe(tap(_ => {
        if(options?.successMessage) {
            toasterService.success(options.successMessage, successTitle);
        }
    }));
}

function _mapToResponseType<T>()
    : OperatorFunction<HttpResponse<T>, T> {
    return pipe(
        map( (response) => response.body),
        filter( (o): o is T => o != null)
    );
}
