import {Component, OnInit} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'ngx-operation-modale-report',
  templateUrl: './modale-report.component.html',
  styleUrls: ['./modale-report.component.scss'],
})

export class ModaleReportComponent {

  public identifiant: number;
  public cause_report_autre: string;
  public cause_report: string;

  constructor(
    private readonly alertService: NbToastrService,
    private readonly activeModal: NgbActiveModal
  ) {
    this.identifiant = new Date().getTime();
    this.cause_report = '';
    this.cause_report_autre = undefined;
  }

  public validate(): void {
    if (!this.cause_report || this.cause_report === '') {
      this.alertService.danger('Veuillez sélectionner une cause de report', 'Erreur');
    } else {
      if (this.cause_report === 'Autre') {
        this.cause_report = this.cause_report_autre;
      }
      this.activeModal.close({'date_report': new Date(), 'cause_report': this.cause_report});
    }
  }

  public dismiss(): void {
    this.activeModal.close(null);
  }

}
