import {Injectable, signal, WritableSignal} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserModel} from '../models/user/user.model';
import {BaseService} from './base.service';
import {UserFilterModel} from '../models/user/userFilter.model';
import {UserGridColumnConfigModel} from '../models/user/user-grid-column-config.model';
import {AUTH_TOKEN_V1_STORAGE_KEY} from '../auth.guard';


@Injectable({providedIn: 'root'})
export class UsersService extends BaseService {

  private url: string = `users/`;

  private readonly columnManagerUrl: string = `${environment.apiPath}column_definitions`;

  currentUser: WritableSignal<UserModel> = signal<UserModel>(null);

  private errorAuth: boolean;
  private successAuth: boolean;

  getMe(): Observable<UserModel> {
    return this.get(environment.apiPath + this.url + 'fromAuthToken/' + localStorage.getItem(AUTH_TOKEN_V1_STORAGE_KEY)).pipe(
      map(item => {
        this.currentUser.set(item as UserModel)
        return new UserModel().fromUser(item as UserModel);
      }),
    );
  }

  getListUser(filter: UserFilterModel): Observable<Array<UserModel>> {
    return this.post(environment.apiPath + this.url + 'list', filter).pipe(
      map(item => {
        return item['users'] as Array<UserModel>;
      }),
    );
  }

  updateUser(user: UserModel): Observable<string> {
    return this.post(environment.apiPath + this.url + 'update', user).pipe(
      map(item => {
        return item as string;
      }),
    );
  }

  resetPassword(user: UserModel): Observable<string> {
    return this.post(environment.apiPath + this.url + 'update', user).pipe(
      map(item => {
        return item as string;
      }),
    );
  }

  insertUser(user: UserModel): Observable<boolean> {
    return this.post(environment.apiPath + this.url, user).pipe(
      map(item => {
        return true;
      }),
    );
  }

  deleteUser(id: number): Observable<boolean> {
    return this.delete(environment.apiPath + this.url + 'delete/' + id).pipe(
      map(item => {
        return true;
      }),
    );
  }

  public getErrorAuth(): boolean {
    return this.errorAuth;
  }

  public setErrorAuth(isErrorAuth: boolean) {
    this.errorAuth = isErrorAuth;
  }

  public getSuccessAuth(): boolean {
    return this.successAuth;
  }

  public setSuccessAuth(isSuccessAuth: boolean) {
    this.successAuth = isSuccessAuth;
  }

  public columnDefinitionState(gridId: string): Observable<UserGridColumnConfigModel[]> {
    let result: Observable<UserGridColumnConfigModel[]>;
    return this.getMe().pipe(
      switchMap(user => {
        result = this.http.get(`${this.columnManagerUrl}/list/${gridId}/${user.id}`) as Observable<UserGridColumnConfigModel[]>;
        return result;
      })
    );
  }

  saveColumnState(gridParams: UserGridColumnConfigModel[]): Observable<any> {
    return this.getMe().pipe(
      switchMap(user => {
        return this.http.put(`${this.columnManagerUrl}/update/${user.id}`, gridParams);
      })
    );
  }
}
