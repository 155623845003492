import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { LoginRequest } from './models/security/login-request.model';
import { LoginV2Response } from './models/security/login-v2-response.model';

export const AUTH_TOKEN_V1_STORAGE_KEY = 'authToken';
export const AUTH_TOKEN_V2_STORAGE_KEY = 'jwt';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem(AUTH_TOKEN_V1_STORAGE_KEY) != null) {
      return true;
    }
    this.router.navigate(['/auth/login']);
    return false;
  }

  createAuthorizationHeader(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    if (localStorage.getItem(AUTH_TOKEN_V1_STORAGE_KEY)) {
      headers = headers.append(AUTH_TOKEN_V1_STORAGE_KEY, localStorage.getItem(AUTH_TOKEN_V1_STORAGE_KEY));
    }
    return headers;
  }

  /*
   * Here, we are sending both login call to the old and new api.
   * This isn't the best way to do this but if this is still here,
   * this meant there wasn't enought time to refactor this.
   *
   * Why not just move the old auth to the recent api?
   * -> Because the old auth is using session and we are moving to a JWT stateless type auth.
   */
  login(login: LoginRequest): Observable<boolean> {
    const urlv1 = environment.apiPath + 'session/login';
    const urlv2 = environment.apiPathV2 + 'session/login';
    return forkJoin({
      v1: this.http.post<any>(urlv1, login, {headers: this.createAuthorizationHeader()}),
      v2: this.http.post<LoginV2Response>(urlv2, login, {headers: this.createAuthorizationHeader()}),
    }).pipe(
        map(resp => {
          localStorage.setItem(AUTH_TOKEN_V1_STORAGE_KEY, resp.v1.authToken);
          localStorage.setItem(AUTH_TOKEN_V2_STORAGE_KEY, resp.v2.token);
          return true;
        }),
        catchError(() => {
          localStorage.removeItem(AUTH_TOKEN_V1_STORAGE_KEY);
          localStorage.removeItem(AUTH_TOKEN_V2_STORAGE_KEY);
          return of(false)
        }),
      );
  }

  logout(): Observable<boolean> {
    return this.http.get(environment.apiPath + 'session/logout', {headers: this.createAuthorizationHeader()}).pipe(
      map(item => {
          return true;
        },
      ),
      catchError(() => {
        return of(false);
      }),
    );
  }
}
