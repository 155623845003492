import {Component} from '@angular/core';

export interface ConfModel {
  video: any,
}

@Component({
  selector: 'ngx-modale-video',
  templateUrl: './modale-video.component.html',
  styleUrls: ['./modale.scss'],
})

export class ModaleVideoComponent implements ConfModel {

  public video;

  public ok(): void {
    // this.result = true;
    // this.close();
  }

  public dismiss(): void {
    // this.result = false;
    // this.close();
  }
}
