import {TreeNode} from "./treenode.model";

export class CreateTreeNode {

  public createTree(data: any[], type : string) : TreeNode[] {
    // Utiliser un objet pour stocker temporairement les nœuds par id parent
    const nodesByParent: { [parentId: number]: TreeNode[] } = {};
    let TreeTemp: TreeNode[] = [];
    let nodeTemp: TreeNode = new TreeNode();

    data.forEach((node: any) => {
        const parentID = node.idParent || 0; // Si idParent est null, utiliser 0 comme parent
        if (!nodesByParent[parentID]) {
          nodesByParent[parentID] = [];
        }
        if (type === 'droit') {
          nodesByParent[parentID].push(new TreeNode().mapDroitsToNodes(node));
        } else if (type === 'usine') {
          nodesByParent[parentID].push(new TreeNode().mapUsinesToNodes(node));
        } else {
          nodesByParent[parentID].push(new TreeNode().mapGenericToNodes(node));
        }
      });

    TreeTemp = nodesByParent[0] || [];
    this.assignChildren(TreeTemp, nodesByParent);

    return TreeTemp;
  }

  private assignChildren(nodes: TreeNode[], nodesByParent: { [parentId: number]: TreeNode[] }) {
    nodes.forEach((node: TreeNode) => {
      const children: TreeNode[] = nodesByParent[node.id] || [];
      if (children.length > 0) {
        node.children = children;
        this.assignChildren(node.children, nodesByParent);
      }
    });
  }
}
