import {Component, Input, ViewChild} from '@angular/core';
import {ColDef, GridApi, RowDragEvent} from "ag-grid-community";
import {AgGridAngular} from "ag-grid-angular";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {GridOptions} from "ag-grid-community/main";
import {UsersService} from "../../../@core/services/users.service";
import {UserGridColumnConfigModel} from "../../../@core/models/user/user-grid-column-config.model";
import {take, takeUntil} from "rxjs/operators";
import {Destroy} from "../../../@core/services/destroy.service";
import {Observable, Subject} from "rxjs";
import {NbToastrService} from "@nebular/theme";

@Component({
  selector: 'ag-grid-column-manager',
  templateUrl: './ag-grid-column-manager.component.html',
  styleUrls: ['./ag-grid-column-manager.component.scss'],
  providers: [Destroy]
})
export class AgGridColumnManagerComponent {
  private static readonly COLUMN_DEFAULT_MIN_WIDTH: number = 200;

  //----

  @ViewChild('columnManager')
  columnManager: HTMLElement;

  //----

  @Input()
  public gridApi: GridApi;

  @Input()
  public gridRef: AgGridAngular<any, ColDef>;

  //----
  private gridParams: UserGridColumnConfigModel[] = []
  public gridOptions: GridOptions<any> | undefined;
  public localGridApi: GridApi | undefined;
  public columnDefs: ColDef[] = [];
  public noFieldColDef: ColDef[] = []
  public loading: boolean = true

  //----
  constructor(
    private readonly offCanvasService: NgbOffcanvas,
    private readonly userService: UsersService,
    private readonly destroy$: Destroy,
    private readonly snackBarService: NbToastrService,
  ) {
    this.gridOptions = {
      rowDragManaged: true,
      animateRows: true,
      columnDefs: [
        {
          lockPosition: true,
          headerName: 'Nom',
          field: 'headerName',
          rowDrag: true,
          checkboxSelection: true,
          showDisabledCheckboxes: true,
        },
      ],
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
    }
  }

  /* * **************** * */

  public onGridReady(params) {
    this.noFieldColDef = this.gridRef.columnDefs.filter((x: ColDef) => x.field === undefined);

    this.noFieldColDef.forEach((x) => {
      x.hide = false;
    });

    this.columnDefs = this.gridRef.columnDefs.filter((x: ColDef) => x.field !== undefined);
    //
    this.localGridApi = params.api;
    this.localGridApi.sizeColumnsToFit({
      defaultMinWidth: AgGridColumnManagerComponent.COLUMN_DEFAULT_MIN_WIDTH
    });
    this.userService.columnDefinitionState(this.gridApi.getGridId()).pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      (e) => {
        this.gridParams = e.filter((x) => this.columnDefs.some(y => y.field == x.name));
        //region set order
        this.columnDefs = this.columnDefs.sort((a, b) => {
          let aOrder = this.gridParams.find(x => x.name == a.field)?.order;
          let bOrder = this.gridParams.find(x => x.name == b.field)?.order;
          if (aOrder && bOrder) {
            return aOrder - bOrder;
          }
          return 0;
        });
        this.columnDefs = [...this.columnDefs]; //? force refresh
        //endregion set order
        this.localGridApi.refreshCells();
        this.loading = false;
      });
    //? the OnRowDataUpdated is trigger at this point. The visibility is set there.
  }

  public openColumnManager(): void {
    this.offCanvasService.open(this.columnManager, {
      position: "end",
      beforeDismiss: () => {
        this.saveColumnState();
        return true;
      },
    });
  }

  refreshVisibility($event) {
    if (($event as any).data.field === undefined) return;
    const findresult = this.gridParams.find(x => x.name == $event.data.field);
    if (findresult) {
      findresult.visible = $event.node.selected;
    }
    //
    this.applyGridParams();
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: AgGridColumnManagerComponent.COLUMN_DEFAULT_MIN_WIDTH
    });
  }

  onRowDataChanged(_) {
    if (!this.localGridApi) return;
    this.localGridApi.forEachNode((node) => {
      let bind = this.gridParams.find(x => x.name == node.data.field);
      if (bind?.visible) {
        node.setSelected(true);
      }
    });
  }

  refreshOrders($event: RowDragEvent) {
    if (($event).node.data.field === undefined) return;
    $event.api.forEachNode((node) => {
      this.gridParams.find(x => x.name == node.data.field).order = node.childIndex;
    });
    //
    this.applyGridParams();
    this.gridApi.sizeColumnsToFit({
      defaultMinWidth: AgGridColumnManagerComponent.COLUMN_DEFAULT_MIN_WIDTH
    });
  }

  applyGridParams() {

    let newCD = this.gridParams.sort((a, b) => (a.order || 0) - (b.order || 0)
    ).map((x) => {
      let colDef = this.columnDefs.find(y => y.field == x.name);
      colDef.hide = !x.visible;
      return colDef;
    });
    this.gridApi.setColumnDefs(
      [...this.noFieldColDef, ...newCD]
    );
  }

  public saveColumnState() {
    this.userService.saveColumnState(this.gridParams)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(
      {
        next: (e) => {
          this.snackBarService.success('Paramètre sauvegardés pour la prochaine session', 'Action réussie');
        },
        error: (e) => {
          this.snackBarService.danger('Sauvegarde échouée');
        },
      }
    )
  }

  /**
   * How to use ? :
   * @see src/app/pages/gestion/contrat/contrat.component.ts
   *
   * @param cd provide a complete column definition
   * @param gridId provide the gridId
   * @param userService inject the userService and provide it
   * @returns a new column definition with the visibility & order set
   * <p>&nbsp</p>
   *
   * @note you'll be more likely to use this method in the onGridReady method
   * <p>&nbsp</p>
   * @note it is highly recommended to se your defaultColDef.lockPosition to true in your GridOptions to avoid conflicts with de column manager
   * <p>&nbsp</p>
   * @note to have a pleasant looks (no data flickering),
   *       you should define two colDef in your component,
   *       one for the grid, and one for the column manager.
   *       i did this in the contrat.component.ts
   * */
  public static getFilteredColumnDefs(cd: ColDef[], gridId: string, userService: UsersService): Observable<ColDef[]> {
    let result$: Subject<ColDef[]> = new Subject<ColDef[]>();
    userService.columnDefinitionState(gridId).pipe(
      take(1)
    ).subscribe(
      (e) => {
        if (e.length === 0) return;

        //region set order
        let ncd = cd.sort((a, b) => {
          let aOrder = e.find(x => x.name == a.field)?.order;
          let bOrder = e.find(x => x.name == b.field)?.order;
          if (aOrder >= 0 && bOrder >= 0) {
            return aOrder - bOrder;
          }
          return 0;
        });
        //endregion set order
        ncd.forEach((x) => {
          if (x.field === undefined) return;
          e.find(y => y.name == x.field)?.visible ? x.hide = false : x.hide = true;
        });

        result$.next([...ncd]); //? force refresh
      });
    return result$.asObservable();
  }
}
