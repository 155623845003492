import {Component, OnInit} from '@angular/core';
import {MoyenService} from '../../../../../@core/services/moyen.service';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

export interface ConfModel {
  usines: any,
  singleSelectedMoyen: any,
  bool_filtre: any,
}

@Component({
  selector: 'ngx-gestion-moyens-modale-ajout',
  templateUrl: './modale-ajout-moyen.component.html',
  styleUrls: ['../../../../modale/modale.scss', './modale-ajout-moyen.component.scss'],
})

export class ModaleAjoutMoyenComponent implements ConfModel, OnInit {

  public usines: any;
  public singleSelectedMoyen: any;
  public bool_filtre: any;
  public tabFiltre: any;
  public showError: boolean;
  public errorTxt: string;

  constructor(private readonly moyenService: MoyenService,
              public readonly activeModal: NgbActiveModal,) {}

  public ngOnInit(): void {
    this.tabFiltre = [];
    this.showError = false;
    this.errorTxt = '';
    if (this.singleSelectedMoyen.idSite < 1) {
      this.singleSelectedMoyen.idSite = Number(this.usines[0].value);
    }
  }

  public validate(): void {
    this.showError = false;
    if (this.singleSelectedMoyen.idSite > 0
      && this.singleSelectedMoyen.categorie != null
      && this.singleSelectedMoyen.type != null
      && this.singleSelectedMoyen.categorie !== ''
      && this.singleSelectedMoyen.type !== ''
      && this.isPositive(this.singleSelectedMoyen.prix_achat)
      && this.isPositive(this.singleSelectedMoyen.prix_vente)) {
      this.activeModal.close(this.singleSelectedMoyen);
    } else {
      if (this.singleSelectedMoyen.site <= 0) {
        this.errorTxt = 'Veuillez sélectionner un site';
      } else if (this.singleSelectedMoyen.categorie === '' || this.singleSelectedMoyen.categorie == null) {
        this.errorTxt = 'Veuillez spécifier une catégorie';
      } else if (this.singleSelectedMoyen.type === '' || this.singleSelectedMoyen.type == null) {
        this.errorTxt = 'Veuillez spécifier un type';
      } else if (!this.isPositive(this.singleSelectedMoyen.prix_achat) || !this.isPositive(this.singleSelectedMoyen.prix_vente)) {
        this.errorTxt = 'Les prix saisis ne peuvent être inférieurs à 0';
      }
      this.showError = true;
    }
  }

  public dismiss(): void {
    this.activeModal.close(null);
  }

  public filtre(champ: string, text: string): void {
    const param = {'text': text, 'champ': champ};
    this.moyenService.getMoyenFiltred(param).subscribe(
      (data) => {
        this.tabFiltre[champ] = data;
        this.bool_filtre[champ] = true;
      },
    );
  }

  public filtre_type(text: string, categorie: any): void {
    const param = { 'text': text, 'champ': 'type', 'categorie': categorie };
    this.moyenService.getMoyenFiltred(param).subscribe(
      (data) => {
        this.tabFiltre['type'] = data;
        this.bool_filtre['type'] = true;
      },
    );
  }

  public toNumber(str) {
    return Number(str);
  }

  public blur(bool: any): void {
    setTimeout(() => this.bool_filtre[bool] = false , 250, true);
  }


  public select(champ: string, text: string): void {
    this.singleSelectedMoyen[champ] = text;
    this.tabFiltre[champ] = [];
  }

  public isPositive(val) {
    return !val || val >= 0;
  }
}
