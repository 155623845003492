export class BusinessUnitModel {

  id: number;
  nom: string;
  active: boolean;

  constructor() {
    this.nom = '';
    this.active = true;
  }
}
