import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {StatutOptionComponent} from '../pages/operation/statut-option/statut-option.component';
import {ModaleMoyenAgGridComponent} from '../pages/operation/modals/modaleMoyenAgGrid/modale-moyen-ag-grid.component';
import {AgGridModule} from 'ag-grid-angular';
import {CommonModule} from '@angular/common';
import {SelectParametresComponent} from './components/select-parametres/select-parametres.component';
import {GetFormArrayPipe} from './pipes/get-form-array.pipe';
import {GetFormArrayAtIndexPipe} from './pipes/get-form-array-at-index.pipe';
import {GetFormControlNumberPipe} from './pipes/get-form-control-number.pipe';
import {GetFormControlFromArrayPipe} from './pipes/get-form-control-from-array.pipe';


@NgModule({
  imports: [
    FormsModule,
    AgGridModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  declarations: [
    StatutOptionComponent,
    ModaleMoyenAgGridComponent,
    SelectParametresComponent,
    GetFormArrayPipe,
    GetFormArrayAtIndexPipe,
    GetFormControlNumberPipe,
    GetFormControlFromArrayPipe,
  ],
  exports: [
    StatutOptionComponent,
    ModaleMoyenAgGridComponent,
    SelectParametresComponent,
    GetFormArrayPipe,
    GetFormArrayAtIndexPipe,
    GetFormControlNumberPipe,
    GetFormControlFromArrayPipe,
  ],
})
export class SharedModule {
}
