import {Injectable} from "@angular/core";
import {NgbModal, NgbModalOptions, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Injectable({providedIn: 'root'})
export class NewmDialogService {

  constructor(
    private readonly modalService: NgbModal,
  ) {
  }

  public closeDialog(dialogComponent) {
    dialogComponent.closeDialog();
  }

  public openDialog(
    content: any,
    config: NgbModalOptions,
    data?: any,
  ): NgbModalRef {
    const modalRef = this.modalService.open(
      content,
      config,
    );


    if (data) {
      for (let key of Object.keys(data)) {
        if (!Object.getOwnPropertyDescriptor(modalRef.componentInstance, key)) {
          console.warn(`le champ "${key}" n'existe pas dans le composant "${content.name}"`);
        }
        modalRef.componentInstance[key] = data[key];
      }
    }

    return modalRef;
  }
}
