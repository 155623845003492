<div class="modal-dialog edition-modale-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5
        class="text-center">{{ (selectedUser.id > 0) ? 'Éditer un utilisateur' : 'Ajouter un utilisateur' }}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
    </div>
    <div *ngIf="showError" class="alert alert-danger" role="alert">{{ errorTxt }}</div>
    <nb-tabset>
      <nb-tab tabTitle="Général">
        <div class="modal-body edition-modale-body">
          <div class="container">
            <div style="flex-wrap: wrap;" class="panel panel-body panel-default d-flex">
              <div class="col-lg-12">
                <label>Prénom</label>
                <div class="form-group relative">
                  <input
                    [disabled]="selectedUser.id > 0 && !droitGestionUtilisateurModifPrenom"
                    class="form-control" type="text" [(ngModel)]="selectedUser.firstName"/>
                </div>
              </div>
              <div class="col-lg-12">
                <label>Nom</label>
                <div class="form-group relative">
                  <input
                    [disabled]="selectedUser.id > 0 && !droitGestionUtilisateurModifNom"
                    class="form-control" type="text" [(ngModel)]="selectedUser.lastName"/>
                </div>
              </div>
              <div class="col-lg-12">
                <label>E-Mail</label>
                <div class="form-group relative">
                  <input
                    [disabled]="selectedUser.id > 0 && !droitGestionUtilisateurModifEmail"
                    class="form-control" [(ngModel)]="selectedUser.email"/>
                </div>
              </div>
              <div *ngIf="selectedUser.id === currentUser.id || selectedUser.id === 0" class="col-lg-12">
                <label>Mot de passe</label>
                <div class="form-group relative">
                  <input
                    autocomplete="new-password"
                    [disabled]="selectedUser.id > 0 && !droitGestionUtilisateurModifPwd"
                    class="form-control" type="password" [(ngModel)]="selectedUser.password"/>
                </div>
              </div>
              <div
                class="col-lg-12">
                <label>Profiles :</label>
                <div class="form-group">
                  <select *ngIf="listProfils" [disabled]="!droitGestionUtilisateurModifProfils"
                          class="form-select" [(ngModel)]="selectedUser.profile.id"
                          (change)="setDroitByProfil()">
                    <option *ngFor="let profile of listProfils"
                            [ngValue]="profile.id">{{ profile.code }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="d-flex justify-content-between w-100">
                <div class="">
                  <div class="relative">
                    <!--                -->
                    <div class="relative d-flex flex-row-reverse mt-2 align-items-end flex mt-1">
                      <label style="font-size: 15px" [for]="cb.id">{{ cb.checked ? 'Activé' : 'Désactivé' }}</label>
                      <input #cb id="active-desactive" type="checkbox" class="form-check-input me-1"
                             style="{width: 17px; height: 17px}"
                             [disabled]="!droitGestionUtilisateurModifEtat"
                             [(ngModel)]="this.selectedUser.active"
                      />
                    </div>
                    <!--                -->
                  </div>
                </div>
                <div class="" *ngIf="selectedUser.id > 0 && droitGestionUtilisateurSuppression">
                  <label>Supprimer</label>
                  <div class="relative">
                    <button class="btn btn-default"
                            [disabled]="!droitGestionUtilisateurSuppression"
                            (click)="deleteUser(selectedUser)">Supprimer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Droits" *ngIf="droitGestionUtilisateurModifDroits">
        <div class="modal-body edition-modale-body">

          <div class="container">
            <app-treeview #treeViewDroit id="treeDroits"
                          [nodes]="nodesDroits"></app-treeview>
          </div>

        </div>
      </nb-tab>
      <nb-tab *ngIf="droitGestionUtilisateurModifUsines" tabTitle="Affectation">
        <div class="modal-body edition-modale-body">

          <div class="container">
            <app-treeview #treeViewUsine id="treeUsines"
                          [nodes]="nodesUsines"></app-treeview>
          </div>

        </div>
      </nb-tab>
    </nb-tabset>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="validate()">Valider</button>
      <button class="btn btn-warning" (click)="dismiss()">Annuler</button>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
