<div>

<!--  /*****************-->
<!--   *  POPOVER  *-->
<!--   *****************/-->
  <div *ngIf="dateTimePickerFormat === DateTimePickerFormatEnum.POPOVER">
    <div class="input-group mr-2 relative" [ngbPopover]="calendarContent" #popover="ngbPopover">
      <input readonly
             class="form-control border-r-none left-align-input"
             [ngClass]="inputClass"
             (blur)="inputBlur($event)"
             [ngModel]="dateString | date:inputDatetimeFormat"
             (change)="onInputChange($event)"
             [disabled]="disabled"
      />

      <div *ngIf="withIcon" class="input-group-append">
        <button
          class="form-control"
          [disabled]="disabled"
          type="button"
        >
          <div class="fa fa-calendar"></div>
        </button>
      </div>
    </div>
  </div>

<!--  /**********************-->
<!--   *  MODALE  *-->
<!--   ********************* */-->

  <div *ngIf="dateTimePickerFormat === DateTimePickerFormatEnum.MODAL">
    <div class="input-group mr-2 relative">
      <input readonly
             class="form-control left-align-input"
             [ngClass]="inputClass"
             (blur)="inputBlur($event)"
             [ngModel]="dateString | date:inputDatetimeFormat"
             (change)="onInputChange($event)"
             [disabled]="disabled"
             (click)="openModal()"
      />

      <div *ngIf="withIcon" class="input-group-append">
        <button
          class="form-control"
          [disabled]="disabled"
          type="button"
          (click)="openModal()"
        >
          <div class="fa fa-calendar"></div>
        </button>
      </div>
    </div>
  </div>

  <ng-template #calendarContent>
    <div class="text-center">
        <ngb-datepicker id="dp" #dp name="datepicker"
                        [ngModel]="datetime"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        (ngModelChange)="checkForDate($event, dp)"></ngb-datepicker>
        <div class="mt-auto time-picker" *ngIf="withTimePicker">
          <ngb-timepicker #tp name="timepicker" class="justify-content-center"
                          [ngModel]="datetime"
                          (ngModelChange)="checkForTime($event)"
                          [seconds]="seconds"
                          [hourStep]="hourStep"
                          [minuteStep]="minuteStep">
          </ngb-timepicker>
      </div>
      <div class="mt-3">
        <button *ngIf="withValidateButton" class="btn btn-primary" (click)="buttonSaveDate($event)">Enregistrer</button>
        <button *ngIf="withCancelButton" class="btn btn-warning" (click)="popover.close()">Annuler</button>
        <button *ngIf="withEraseButton" class="btn btn-danger" (click)="buttonEraseDate()">Effacer</button>
      </div>
    </div>
  </ng-template>
</div>
