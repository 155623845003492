import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {
  NbActionsModule,
  NbButtonModule,
  NbContextMenuModule,
  NbIconModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbThemeModule,
  NbUserModule,
} from '@nebular/theme';
import {NbEvaIconsModule} from '@nebular/eva-icons';
import {NbSecurityModule} from '@nebular/security';

import {FooterComponent, HeaderComponent, SearchInputComponent, TinyMCEComponent,} from './components';
import {CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe,} from './pipes';
import {OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent,} from './layouts';
import {DEFAULT_THEME} from './styles/theme.default';
import {COSMIC_THEME} from './styles/theme.cosmic';
import {CORPORATE_THEME} from './styles/theme.corporate';
import {DARK_THEME} from './styles/theme.dark';
import {ThemeSettingsComponent} from './components/theme-settings/theme-settings.component';
import {LayoutDirectionSwitcherComponent} from './components/layout-direction-switcher/layout-direction-switcher.component';
import {SwitcherComponent} from './components/switcher/switcher.component';
import {AgGridColumnManagerComponent} from './components/ag-grid-column-manager/ag-grid-column-manager.component';
import {AgGridModule} from 'ag-grid-angular';
import {DateTimePickerComponent} from './components/date-time-picker/date-time-picker.component';
import {FormsModule} from '@angular/forms';
import {NgbDatepicker, NgbPopover, NgbTimepicker} from '@ng-bootstrap/ng-bootstrap';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import {DateTimePickerModalComponent} from './components/date-time-picker/modal/date-time-picker-modal.component';

registerLocaleData(localeFr, 'fr', localeFrExtra);

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  DateTimePickerComponent,
  DateTimePickerModalComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];

@NgModule({
  imports: [CommonModule, ...NB_MODULES, AgGridModule, FormsModule, NgbPopover, NgbDatepicker, NgbTimepicker],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ThemeSettingsComponent, AgGridColumnManagerComponent],
  declarations: [...COMPONENTS, ...PIPES, ThemeSettingsComponent, LayoutDirectionSwitcherComponent, SwitcherComponent, AgGridColumnManagerComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME],
        ).providers,
      ],
    };
  }
}
