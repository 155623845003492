<div class="modal-dialog edition-modale-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5
        class="text-center">{{ (selectedProfile.id > 0) ? 'Éditer un profil' : 'Ajouter un profil' }}</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
    </div>
    <div *ngIf="showError" class="alert alert-danger" role="alert">{{ errorTxt }}</div>
    <nb-tabset>
      <nb-tab tabTitle="Profile">
        <div class="modal-body edition-modale-body">
          <div class="container">
            <div style="flex-wrap: wrap;" class="panel panel-body panel-default d-flex">
              <div class="col-lg-12">
                <label>Code</label>
                <div class="form-group relative">
                  <input [disabled]="true" class="form-control" type="text"
                         (input)="changeText($event.target.value)"
                         [(ngModel)]="selectedProfile.code"/>
                </div>
              </div>
              <div class="col-lg-12">
                <label>Libellé</label>
                <div class="form-group relative">
                  <input class="form-control" type="text"
                         (input)="changeText($event.target.value)"
                         [(ngModel)]="selectedProfile.libelle"/>
                </div>
              </div>
              <div class="col-lg-12">
                <label>Description</label>
                <div class="form-group relative">
              <textarea class="form-control"
                        [(ngModel)]="selectedProfile.description"></textarea>
                </div>
              </div>
              <div *ngIf="selectedProfile.id > 0" class="form-group col-lg-4">
                <label>Activation</label>
                <div class="form-group relative">
                  <button class="btn active-button"
                          [disabled]="!currentUser || !droitGestionProfilModifEtat"
                          [ngClass]="(!selectedProfile.actif) ? 'btn-primary' : 'btn-warning'"
                          (click)="setActif(!selectedProfile.actif)">{{ !selectedProfile.actif ? "Activer" : "Désactiver" }}
                  </button>
                </div>
              </div>
              <div *ngIf="isAdmin" class="form-group col-lg-4">
                <!--                -->
                <div class="form-group relative d-flex flex-row-reverse mt-2 align-items-end flex mt-1">
                  <label [for]="cb.id" style="font-size: 15px">{{ cb.checked ? 'Administrateur' : 'Non administrateur' }}</label>
                  <input #cb id="active/desactive" type="checkbox" class="form-check-input me-1" style="{width: 17px; height: 17px}"
                         [disabled]="!droitGestionProfilModifEtat"
                         [(ngModel)]="selectedProfile.admin"
                  />
                </div>
                <!--                -->
              </div>
              <div *ngIf="droitGestionProfilSupprimer && selectedProfile.id > 0" class="form-group col-lg-4">
                <label>Supprimer</label>
                <div class="form-group relative">
                  <button class="btn btn-default"
                          [disabled]="!currentUser || !droitGestionProfilSupprimer"
                          (click)="deleteProfile(selectedProfile.id)">Supprimer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Attribution des droits">
        <div class="container">
          <app-treeview #treeView id="treeview"
                        [nodes]="nodesDroits"></app-treeview>
        </div>
      </nb-tab>
    </nb-tabset>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="validate()">Valider</button>
      <button class="btn btn-warning" (click)="dismiss()">Annuler</button>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
