import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

export interface ConfModel {
  usines: any,
}


@Component({
  selector: 'ngx-gestion-moyens-modale-import',
  templateUrl: './modale-import.component.html',
  styleUrls: ['../../../../modale/modale.scss'],
})

export class ModaleImportComponent implements ConfModel, OnInit {

  public selected_usine = -1;
  public importMoyen: any;
  public usines: any;
  public errorTxt: string;
  public showError: boolean;

  constructor(public readonly activeModal: NgbActiveModal,) {
  }
  public ngOnInit(): void {
    this.errorTxt = '';
    this.showError = false;
  }

  public validate(): void {
    if (this.selected_usine == null || this.selected_usine < 1) {
      this.errorTxt = 'Veuillez sélectionner un site';
      this.showError = true;
    } else if (!this.importMoyen) {
      this.errorTxt = 'Veuillez sélectionner un document à importer';
      this.showError = true;
    } else {
      this.showError = false;
      this.activeModal.close({
        file: this.importMoyen,
        site: this.selected_usine,});
    }
  }

  public dismiss(): void {
    this.activeModal.close({});
  }

  public onFileChange(file: File): void {
    this.importMoyen = file;
  }
}
