<div class="d-flex flex-row justify-content-center">
  <div class="d-flex chevron-left flex-column justify-content-center" *ngIf="showChevronLeft" (click)="clickChevronLeft()">
    <span>
      <i class="fa fa-chevron-left fa-5x text-white"></i>
    </span>
  </div>
  <div class="modal-dialog modale-img" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modaleDelLabel">Détails des photos</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
      </div>
      <div class="modal-body">
        <img class="mw-100" [src]="actualImgSrc" [alt]="imgDescription" />
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
  <div class="d-flex chevron-right flex-column justify-content-center" *ngIf="showChevronRight" (click)="clickChevronRight()">
    <span>
      <i class="fa fa-chevron-right fa-5x text-white"></i>
    </span>
  </div>
</div>
