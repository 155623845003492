import {Component, Input} from '@angular/core';
import {NgbActiveModal, NgbDatepicker, NgbDateStruct, NgbTimeStruct} from "@ng-bootstrap/ng-bootstrap";
import {MoyenModel} from "../../../../@core/models/moyens/moyen.model";
import {DateTimeModel} from "../date-time.model";
import {takeUntil} from "rxjs/operators";
import {ResponseModel} from "../../../../@core/models/response.model";

export interface ConfModel {
  datetime: DateTimeModel;
  minDate?: NgbDateStruct;
  minTime?: NgbTimeStruct;
  maxDate?: NgbDateStruct;
  maxTime?: NgbTimeStruct;
  withTimePicker?: boolean;
  hourStep?: number;
  minuteStep?: number;
  secondStep?: number;
  seconds?: boolean;
  withEraseButton?: boolean;
}

@Component({
  selector: 'date-time-picker-modal',
  templateUrl: './date-time-picker-modal.component.html',
  styleUrls: ['./date-time-picker-modal.component.scss']
})
export class DateTimePickerModalComponent {

  protected datetime: DateTimeModel;
  protected minDate: NgbDateStruct;
  protected minTime: NgbTimeStruct;
  protected maxDate: NgbDateStruct;
  protected maxTime: NgbTimeStruct;
  protected withTimePicker: boolean = true;
  protected hourStep?: number = 1;
  protected minuteStep?: number = 1;
  protected secondStep?: number = 30;
  protected seconds?: boolean = false;

  private isEqualsToMinDate: boolean = false;
  private isEqualsToMaxDate: boolean = false;

  private isUnderMinTime: boolean = false;
  private isOverMaxTime: boolean = false;

  protected withValidateButton: boolean = true;
  protected withCancelButton: boolean = true;
  protected withEraseButton?: boolean = false;

  public showError: boolean;
  public errorTxt: string;
  constructor(
    private readonly activeModal: NgbActiveModal,
  ) {
  }

  dateTimeIsOk(newDateTime: DateTimeModel): boolean{
    if (newDateTime != null) {
      if(this.minDate || this.maxDate) {
        this.isEqualsToMinDate = this.equalsDate(newDateTime, this.minDate);
        this.isEqualsToMaxDate = this.equalsDate(newDateTime, this.maxDate);
      }

      if (this.withTimePicker && (this.minTime || this.maxTime)) {
        const tempTimeStruct: NgbTimeStruct = {
          hour: newDateTime.hour,
          minute: newDateTime.minute,
          second: newDateTime.second,
        };

        this.isUnderMinTime = !this.time1UnderTime2(this.minTime, tempTimeStruct);
        this.isOverMaxTime = !this.time1UnderTime2(tempTimeStruct, this.maxTime);
      }

        if (this.withTimePicker && ((this.isEqualsToMinDate && this.isUnderMinTime) || (this.isEqualsToMaxDate && this.isOverMaxTime))) {
          return false;
        } else {
          return true;
        }
    } else {
      return false;
    }
  }

  onDateChange($event: string | NgbDateStruct): void {
    const date: DateTimeModel = new DateTimeModel($event);

    if (!this.datetime) {
      this.datetime = date;
    }

    this.datetime.year = date.year;
    this.datetime.month = date.month;
    this.datetime.day = date.day;
  }

  onTimeChange(event: NgbTimeStruct): void {
    if(event){
      this.datetime.hour = event.hour;
      this.datetime.minute = event.minute;
    }
  }

  equalsDate(date1: NgbDateStruct, date2: NgbDateStruct): boolean{
    if(date1 && date2){
      const tempDate1: Date = new Date(date1.year, date1.month, date1.day);
      const tempDate2: Date = new Date(date2.year, date2.month, date2.day);
      return tempDate1.getTime() === tempDate2.getTime();
    }
  }

  time1UnderTime2(time1: NgbTimeStruct, time2: NgbTimeStruct): boolean{
    return time1 && time2 && time1.hour <= time2.hour && time1.minute < time2.minute;
  }

  saveDate() {
    this.showError = false;
    if (this.datetime) {
      if (!this.dateTimeIsOk(this.datetime)) {
        this.errorTxt = 'La date et l\'heure doivent être supérieures à la date et l\'heure minimales et inférieures à la date et l\'heure maximales';
        this.showError = true;
      } else {
        if(!this.withTimePicker){
          this.datetime.hour = 1;
        }
        this.activeModal.close(this.datetime);
      }
    }
  }
  public closeDialog() {
    this.activeModal.close(null);
  }

}
