export class UserDroitFilterModel {

  pageSize: number;
  page: number;
  idProfile: number;
  idUser: number;
  all: Boolean;


}
