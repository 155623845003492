import {TypeFicheExpressionBesoinEnum} from './enum/TypeFicheExpressionBesoin.enum';

export class FiltreModel {

  preparateur: any;
  status: any;
  usine: any;
  OT: any;
  num_avis: any;

  actif: boolean;
  preparee: string;
  no_caisse: string;
  page: number;
  pageSize: number;
  simple: boolean;
  atelier: number;
  text: string;
  listStatus: string[];
  listPreparateur: number[];
  listAtelier: string[];
  listUsine: number[];

  // Filtres des fiches expression besoin
  dateDebut: Date;
  dateFin: Date;
  listType: TypeFicheExpressionBesoinEnum[];


}
