import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NbMenuService, NbSidebarService, NbToastrService} from '@nebular/theme';
import {AuthGuard} from '../../../@core/auth.guard';
import {UserModel} from '../../../@core/models/user/user.model';
import {UsersService} from '../../../@core/services/users.service';
import {BoutonFiltreService} from '../../../@core/services/bouton-filtre.service';
import {environment} from '../../../../environments/environment';
import {MenuService} from '../../../@core/services/menu.service';
import {Router} from '@angular/router';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input() position = 'normal';

  me: UserModel;

  userMenu = [{title: 'Tutoriel'}, {title: 'Applications android'}, {title: 'Déconnexion'}];

  public isOpen: boolean;
  @Output() openFilter: EventEmitter<boolean> = new EventEmitter();


  constructor(private readonly router: Router,
              private readonly sidebarService: NbSidebarService,
              private readonly menuService: NbMenuService,
              private readonly userService: UsersService,
              private readonly authGuard: AuthGuard,
              public readonly boutonFiltreService: BoutonFiltreService,
              private readonly menuToggleService: MenuService) {
  }

  getNomComplet(): string {
    if (this.me != null) {
      return this.me.firstName + ' ' + this.me.lastName;
    }
    return '';
  }

  ngOnInit() {
    this.userService.getMe().subscribe((me: UserModel) => {
        if (me.listDroit.length === 0) {
          this.userService.setErrorAuth(true);
          localStorage.clear();
          window.location.reload();
        } else {
          this.me = me;
        }
      },
      (err) => {
        localStorage.clear();
        this.userService.setErrorAuth(true);
        window.location.reload();
      });

    this.menuService.onItemClick().subscribe(menus => {
      if (menus.item.title === 'Déconnexion') {
        this.authGuard.logout().subscribe(res => {
          if (res) {
            localStorage.clear();
            window.location.reload();
          }
        });
      }
      if (menus.item.title === 'Tutoriel') {
        window.open(environment.apiPath + 'document/getInfoPdfWeb/' + 1);
      }
      if (menus.item.title === 'Applications android') {
        this.router.navigate(['listVersion'])
      }
    });

    this.isOpen = false;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.menuToggleService.toggleOpen();
    return false;
  }

  toggleSettings(): boolean {
    this.sidebarService.toggle(false, 'settings-sidebar');
    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  public clickOnFilterButton(): void {
    this.boutonFiltreService.toogleFilter();
  }
}
