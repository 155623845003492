<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title" id="modaleDelLabel">Report</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
    </div>
    <div class="modal-body">
      <div class="d-flex">
        <div class="form-group col-lg-12">
          <label>Cause Report</label>
          <div class="select-container">
            <select class="form-select" [(ngModel)]="cause_report">
              <option value="Entreprise Ext.">Entreprise Ext.</option>
              <option value="Approvisionnement">Approvisionnement</option>
              <option value="Annulé pour urgence">Annulé pour urgence</option>
              <option value="A faire sur arrêt">A faire sur arrêt</option>
              <option value="Annulé par la fab">Annulé par la fab</option>
              <option value="mise à dispo. non possible">mise à dispo. non possible</option>
              <option value="Autre">Autre</option>
            </select>
          </div>
        </div>
      </div>
      <div *ngIf="cause_report === 'Autre'" class="form-group col-lg-12">
        <input maxlength="255" type="text" class="form-control" [(ngModel)]="cause_report_autre"/>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="button" (click)="validate()">OK</button>
      <button class="btn btn-warning" type="button" (click)="dismiss()">Annuler</button>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
