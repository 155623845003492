import { Pipe, PipeTransform } from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';

@Pipe({
  name: 'getFormControlFromArray',
})
export class GetFormControlFromArrayPipe implements PipeTransform {

  transform(value: FormGroup, formArrayName: string, index: number, formControlName: string): FormControl {
    if (!value || !formArrayName || index < 0) {
      return new FormControl();
    }

    const formArray = value.get(formArrayName) as FormArray;
    const formGroup = formArray.at(index) as FormGroup;
    return formGroup.get(formControlName) as FormControl;
  }

}
