import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {FichePreparationService} from '../../../@core/services/fiche-preparation.service';
import {NbToastrService} from '@nebular/theme';
import {FiltreModel} from '../../../@core/models/filtre';
import {FichePreparationModel} from '../../../@core/models/fichePreparation';

@Component({
  selector: 'ngx-status-option',
  templateUrl: './statut-option.component.html',
  styleUrls: ['./statut-option.component.scss'],
})
export class StatutOptionComponent implements ICellRendererAngularComp {

  public statut: any;
  public statusType: string;

  constructor(private readonly fichePreparationService: FichePreparationService,
              private readonly alertService: NbToastrService) {
  }

  agInit(statut: any): void {
    this.statusType = statut.value;
    this.statut = statut;
  }

  public updateStatus(): void {
    if (this.statut.fromGrouped) {
      const dataInput: FiltreModel = new FiltreModel();
      dataInput.OT = this.statut.data.no_ot_at;
      dataInput.num_avis = this.statut.data.num_avis;

      let receivedList: any[];
      dataInput['actif'] = true;
      this.fichePreparationService.getListFichePreparation(dataInput).subscribe(
        (data: Array<FichePreparationModel>) => {
          receivedList = data['data']; // récupère tout les fiches
        },
        (err) => {
          /**/
        },
        () => {
          receivedList.forEach((statusToUpdate) => {
            const objStatus = {
              id: statusToUpdate.id,
              status: this.statusType,
            };
            this.updateSingleStatus(objStatus);
          })
        });
    } else {
      const objStatus = {
        id: this.statut.data.id,
        status: this.statusType,
      };
      this.updateSingleStatus(objStatus);
    }
  }

  private updateSingleStatus(objStatus): void {
    this.fichePreparationService.updateStatus(objStatus).subscribe(
      () => {
        this.alertService.success('Le status de la fiche à été enregistré avec succès', 'Action réussie');
        if (this.statusType === 'A_VOIR' || this.statusType === 'ANNULEE') {
          this.statut.data.date_debut = null;
          this.statut.data.date_fin = null;
          this.statut.api.refreshCells({force: false});
        }
        this.statut.setValue(this.statusType);
      },
      (err) => {
        this.alertService.danger('Erreur lors de l\'enregistrement du status', 'Erreur');
      });
  }

  refresh(): boolean {
    return false;
  }
}
