import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {BaseService} from './base.service';
import {map} from 'rxjs/operators';
import {MoyenModel} from '../models/moyens/moyen.model';
import {FavorisModel} from '../models/moyens/favoris.model';
import {ExtractionFilterModel} from "../models/extractionFilter.model";
import {HttpHeaders, HttpResponse} from "@angular/common/http";
import { MimeTypeEnum } from '../models/enum/mime-type-enum';

@Injectable({providedIn: 'root'})
export class MoyenService extends BaseService {
  private url:string = 'moyens/'

  importTuto(i, pdf): Observable<boolean> {
    const file: File = pdf;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.postFile(environment.apiPath + 'document/importPdf/' + i, formData).pipe(
      map(item => {
        return true;
      }),
    );
  }

  getSingleMoyen(idMoyen): Observable<MoyenModel> {
    return this.get(environment.apiPath + 'moyens/' + idMoyen).pipe(
      map(item => {
        return item as MoyenModel;
      }))
  }

  getListMoyens(filtre): Observable<any> {
    return this.post(environment.apiPath + 'moyens/list/', filtre).pipe(
      map(item => {
        return item;
      }))
  }

  getListRecurrences(filtre): Observable<any> {
    return this.post(environment.apiPath + 'moyens/recurrences/', filtre).pipe(
      map(item => {
        return item;
      }))
  }

  getRecurrence(id): Observable<any> {
    return this.get(environment.apiPath + 'moyens/recurrence/' + id).pipe(
      map(item => {
        return item;
      }))
  }

  deleteMoyen(array): Observable<any> {
    return this.post(environment.apiPath + 'moyens/delete/', array).pipe(
      map(item => {
        return item;
      }))
  }

  addMoyen(moyen): Observable<any> {
    return this.post(environment.apiPath + 'moyens/add/', moyen).pipe(
      map(item => {
        return item as any;
      }),
    )
  }

  deleteRecurrence(recurrenceId): Observable<any> {
    return this.post(environment.apiPath + 'moyens/delRecurrence/', recurrenceId).pipe(
      map(item => {
        return item;
      }))
  }

  getNbRecurrences(idUsine): Observable<any> {
      return this.get(environment.apiPath + 'moyens/nbrecurrences/' + idUsine).pipe(
      map(item => {
        return item;
      }))
  }

  getNbMoyens(moyenFilter): Observable<any> {
    return this.post(environment.apiPath + 'moyens/nbmoyens', moyenFilter).pipe(
      map(item => {
        return item;
      }))
  }

  getMoyenFiltred(moyenFilter): Observable<Array<MoyenModel>> {
    return this.post(environment.apiPath + 'moyens/filtre/', moyenFilter).pipe(
      map(item => {
        return item as Array<MoyenModel>;
      }),
    );
  }

  getMoyenByKeyWord(moyenFilter): Observable<Array<MoyenModel>> {
    return this.post(environment.apiPath + 'moyens/list/', moyenFilter).pipe(
      map(item => {
        return item as Array<MoyenModel>;
      }),
    );
  }

  getMoyenFavoris(id): Observable<Array<FavorisModel>> {
    return this.get(environment.apiPath + 'moyens/favoris/' + id).pipe(
      map(item => {
        return item as Array<FavorisModel>;
      }),
    )
  }

  addMoyenFavoris(favoris): Observable<any> {
    return this.post(environment.apiPath + 'moyens/addfavoris/', favoris).pipe(
      map(item => {
        return item as any;
      }),
    )
  }

  newRecurrence(recurrence): Observable<any> {
    return this.post(environment.apiPath + 'moyens/newrecurrence/', recurrence).pipe(
      map(item => {
        return item as any;
      }),
    )
  }

  addRecurrence(recurrence): Observable<any> {
    return this.post(environment.apiPath + 'moyens/addrecurrences/', recurrence).pipe(
      map(item => {
        return item as any;
      }),
    )
  }

  importMoyen(usineValue, xls): Observable<any> {
    const file: File = xls;
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('site', usineValue);
    return this.postFile(environment.apiPath + 'moyens/import/', formData).pipe(
      map(item => {
        return item;
      }),
    );
  }

  getMatiere(temp): Observable<any> {
    return this.post(environment.apiPath + 'moyens/matiere/', temp).pipe(
      map(item => {
        return item;
      }),
    );
  }

  getType(temp): Observable<any> {
    return this.post(environment.apiPath + 'moyens/type/', temp).pipe(
      map(item => {
        return item;
      }),
    );
  }

  getCategories(idUsine): Observable<any> {
    return this.get(environment.apiPath + 'moyens/categories/' + idUsine).pipe(
      map(item => {
        return item;
      }),
    );
  }

  exportMoyens(filtre: any): Observable<HttpResponse<Blob>> {
    let filtreToSend: MoyenModel = new MoyenModel();
    //On veut filtrer seulement sur l'usine
    filtreToSend.site = filtre.site;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${environment.apiPath}${this.url}export/`, filtreToSend, { headers, observe: 'response', responseType: 'blob' })
      .pipe(
        map(response => {
          this.downloadFile(response);
          return response;
        })
      );
  }

  private downloadFile(response: HttpResponse<Blob>): void {
    const blob = new Blob([response.body], { type: MimeTypeEnum.XLSX });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'export_moyens';
    link.click();
  }
}

