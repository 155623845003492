<div class="modal-container" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title">Séléction de moyen dans la base de données</h3>
      <span class="recherche">
        <input [(ngModel)]="filtre_mot_cle" (change)="filtreMoyenMotCle()"/>
        <i class="fa fa-search"></i>
      </span>
      <span class="fa fa-remove" (click)="dismiss()"></span>
    </div>
    <div class="modal-body row" id="ag-grid-modal-moyen">
      <div class="tree-view">
        <ul>
          <li *ngFor="let categorie of categories">
            <p class="drop-down-btn" [ngClass]="(categorie.actif ? 'opened':'closed')" (click)="getType(categorie)">
              {{(categorie.name != null ? categorie.name : 'NULL')}} ({{categorie.nombre}})</p>
            <ul>
              <ng-container *ngIf="categorie.actif">
                <li *ngFor="let type of categorie.type">
                  <p class="drop-down-btn" [ngClass]="(type.actif?'opened':'closed')"
                     (click)="getMatiere(categorie.name, type)">{{type.name}} ({{type.nombre}})</p>
                  <ul>
                    <ng-container *ngIf="type.actif">
                      <li *ngFor="let matiere of type.matiere">
                        <p class="drop-down-btn matiere" (click)="getMoyens(categorie.name, type.name, matiere)">{{matiere.name}}
                          ({{matiere.nombre}})</p>
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>
          </li>
        </ul>
      </div>
      <div class="ag-grid">
        <!--                         [showToolPanel]="true"-->
        <ag-grid-angular class="ag-theme-balham"
                         #agMoyen
                         [columnDefs]="ColumnDef"
                         [rowHeight]="30"
                         [pivotPanelShow]="true"
                         [rowSelection]="'multiple'"
                         [suppressRowClickSelection]="true"
                         [gridOptions]="agGridOptions"
                         [rowData]="agGridData">
        </ag-grid-angular>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="button" (click)="validate()">Valider</button>
      <button class="btn btn-warning" type="button" (click)="dismiss()">Annuler</button>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show"></div>
