import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {FichePreparationService} from '../../../@core/services/fiche-preparation.service';
import {NbToastrService} from '@nebular/theme';
import {ModaleReportComponent} from "../modals/modaleReport/modale-report.component";
import {NewmDialogService} from "../../../@core/services/newm-dialog.service";
import {take, takeUntil} from "rxjs/operators";
import {Destroy} from "../../../@core/services/destroy.service";

@Component({
  selector: 'ngx-report-button',
  templateUrl: 'report-button.component.html',
  styleUrls: ['./report-button.component.scss'],
  providers: [Destroy],
})
export class ReportButtonComponent implements ICellRendererAngularComp {

  public action: any;
  private ficheId: number;

  constructor(private readonly fichePreparationService: FichePreparationService,
              private readonly newmDialogService: NewmDialogService,
              private readonly alertService: NbToastrService,
              private readonly destroy$: Destroy) {
  }

  agInit(action: any): void {
    this.action = action;
    this.ficheId = action.data.id;
  }

  public openModale(): void {
    this.newmDialogService.openDialog(ModaleReportComponent,
      {size: 'lg', centered: true, backdrop: 'static', keyboard: true})
      .closed
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe({
        next: (report) => {
          if (report != null) {
            report['idFiche'] = this.ficheId;
            this.fichePreparationService.putReport(report).subscribe();
            this.action.data.nbReport++;
            this.action.data.date_debut = null;
            this.action.data.date_fin = null;
            this.action.data.status = 'VUE';
            this.action.api.refreshCells({force: false});
            this.alertService.success('Report sauvegardé avec succès', 'Action réussie');
          }
        },
        error: (err) => {
          this.alertService.danger('Une erreur est survenue lors de l\'enregistrement du report', 'Erreur');
        }
      });
  }

  refresh(): boolean {
    return false;
  }
}
