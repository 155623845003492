import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {AtelierModel} from '../../../@core/models/atelier.model';
import {ListeService} from '../../../@core/services/liste.service';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'ngx-atelier-renderer',
  template: `
    <div *ngIf="listeAteliers">{{atelierRenderer()}}</div>`,
})
export class AtelierRendererComponent implements ICellRendererAngularComp {
  public atelier: any;
  public listeAteliers: Array<AtelierModel>;

  agInit(params: any): void {
    this.atelier = params.node.data.id_atelier;
    this.listeAteliers = params.listeAteliers;
  }

  public atelierRenderer(): string {
    if (this.atelier !== null && this.atelier !== 0 && this.atelier !== -1) {
      for (let i = 0; i < this.listeAteliers.length; i++) {
        if (this.atelier === this.listeAteliers[i].id) {
          if (this.listeAteliers[i].libelle_sap) {
            return this.listeAteliers[i].label + ' - ' + this.listeAteliers[i].libelle_sap;
          } else {
            return this.listeAteliers[i].label;
          }
        }
      }
    }
    return '';
  }

  refresh(): boolean {
    return false;
  }
}
